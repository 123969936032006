import { ClientApplicationNewFeatureStatus } from './ClientApplicationNewFeatureStatus.model';
import { CurrentUserLikeOrDislike } from './CurrentUserLikeOrDislike.model';
import { BaseModel, StrictBaseModelPart, EnumProperty, hasUnionKeys } from '@dev-stream/utils';

export class ApplicationNewFeatureViewModel extends BaseModel<ApplicationNewFeatureViewModel> {
    protected BASE_TYPE = ApplicationNewFeatureViewModel;
    'ApplicationId'!: string | null;
    'CompanyName'!: string | null;
    'BIN'!: string | null;
    'Phone'!: string | null;
    'Email'!: string | null;
    'Title'!: string | null;
    'Description'!: string | null;
    @EnumProperty(() => ClientApplicationNewFeatureStatus)
    'Status'!: ClientApplicationNewFeatureStatus | null;
    'IsInternal'!: boolean | null;
    'IsExternal'!: boolean | null;
    'Likes'!: number | null;
    'DisLikes'!: number | null;
    @EnumProperty(() => CurrentUserLikeOrDislike)
    'CurrentUserVote'!: CurrentUserLikeOrDislike | null;

    public GetDefaultValue(): StrictBaseModelPart<ApplicationNewFeatureViewModel> {
        return {
            ApplicationId: null,
            CompanyName: null,
            BIN: null,
            Phone: null,
            Email: null,
            Title: null,
            Description: null,
            Status: null,
            IsInternal: null,
            IsExternal: null,
            Likes: null,
            DisLikes: null,
            CurrentUserVote: null,
        };
    }

    public static isApplicationNewFeatureViewModel(o: any): o is ApplicationNewFeatureViewModel {
        return (
            o instanceof ApplicationNewFeatureViewModel ||
            hasUnionKeys(o, [
                'ApplicationId',
                'CompanyName',
                'BIN',
                'Phone',
                'Email',
                'Title',
                'Description',
                'Status',
                'IsInternal',
                'IsExternal',
                'Likes',
                'DisLikes',
                'CurrentUserVote',
            ])
        );
    }
}
