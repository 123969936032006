import { Component, Injector, OnInit } from '@angular/core';
import { mapRight } from '@dev-stream/utils';
import { FeatureInfoVm } from '@idocs/company-ui/components/feature-info';
import { SharedFeatureService } from '@idocs/shared-logic/shared-feature/services/shared-feature.service';
import { BaseComponent } from '@idocs/shared-ui/core';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'shared-ui-features-page',
    templateUrl: './features-page.component.html',
    styleUrls: ['./features-page.component.scss'],
})
export class FeaturesPageComponent extends BaseComponent implements OnInit {
    constructor(injector: Injector, private service: SharedFeatureService) {
        super(injector);
    }

    items: FeatureInfoVm[] = [];

    ngOnInit(): void {
        this.load();
    }

    load() {
        this.loading = true;
        this.service
            .getFeatures()
            .pipe(
                takeUntil(this.destroyed),
                tap(() => (this.loading = false)),
                mapRight((res) => res),
                mapRight((res) => this.service.mapToFeatureInfo(res)),
                mapRight((res) =>
                    res.sort((a, b) => b.LikeCount - a.LikeCount)
                ),
                mapRight((res) => (this.items = res))
            )
            .subscribe();
    }

    add() {
        this.service
            .openAddFeatueBlade()
            .onClose.pipe(
                takeUntil(this.destroyed),
                tap(() => (this.loading = true)),
                filter((res) => !!res),
                map((res) => this.load()),
                tap(() => (this.loading = false))
            )
            .subscribe();
    }

    like(item: FeatureInfoVm) {
        this.loading = true;
        this.service
            .like(item.ApplicationId)
            .pipe(
                takeUntil(this.destroyed),
                tap(() => (this.loading = false)),
                mapRight((r) => {
                    const index = this.items.findIndex(
                        (i) => i.ApplicationId === item.ApplicationId
                    );

                    this.items[index].LikeCount = r.Payload?.Likes ?? 0;
                    this.items[index].DislikeCount = r.Payload?.Dislikes ?? 0;
                    this.items[index].Disliked = false;
                    this.items[index].Liked = true;
                })
            )
            .subscribe();
    }

    dislike(item: FeatureInfoVm) {
        this.loading = true;
        this.service
            .dislike(item.ApplicationId)
            .pipe(
                takeUntil(this.destroyed),
                tap(() => (this.loading = false)),
                mapRight((r) => {
                    const index = this.items.findIndex(
                        (i) => i.ApplicationId === item.ApplicationId
                    );

                    this.items[index].LikeCount = r.Payload?.Likes ?? 0;
                    this.items[index].DislikeCount = r.Payload?.Dislikes ?? 0;
                    this.items[index].Liked = false;
                    this.items[index].Disliked = true;
                })
            )
            .subscribe();
    }
}
