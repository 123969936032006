import { BaseModel, StrictBaseModelPart } from '@dev-stream/utils';
import { DictionaryVm } from '../shared-ui.models';

export class InstructionsItemVm extends BaseModel<InstructionsItemVm> {
    GetDefaultValue(): StrictBaseModelPart<InstructionsItemVm, never> {
        return {
            Title: '',
            FileName: '',
            Id: '',
            StorageId: '',
        };
    }
    protected BASE_TYPE = InstructionsItemVm;

    Id!: string;
    Title!: string;
    StorageId!: string;
    FileName!: string;
}

export class InstructionsListVm extends BaseModel<InstructionsListVm> {
    GetDefaultValue(): StrictBaseModelPart<InstructionsListVm, never> {
        return {
            Title: '',
        };
    }
    protected BASE_TYPE = InstructionsListVm;

    Title!: string;
    Items?: InstructionsItemVm[];
}
