import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import { FeatureInfoVm } from '../models';

@Component({
    selector: 'company-ui-feature-info',
    templateUrl: './feature-info.component.html',
    styleUrls: ['./feature-info.component.scss'],
})
export class FeatureInfoComponent extends BaseComponent implements OnInit {
    constructor(injector: Injector) {
        super(injector);
    }

    @Input() item: FeatureInfoVm = new FeatureInfoVm({});
    @Output() likeClick = new EventEmitter<FeatureInfoVm>();
    @Output() dislikeClick = new EventEmitter<FeatureInfoVm>();

    ngOnInit(): void {}

    likeBtnClicked() {
        this.likeClick.emit(this.item.Clone());
    }

    dislikeBtnClicked() {
        this.dislikeClick.emit(this.item.Clone());
    }
}
