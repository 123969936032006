import { ApplicationEnvironment } from './environment.model';

export const environment = new ApplicationEnvironment({
    apiServerUrl: 'https://api.refactor.idocs.kz',
    identityServerUrl: 'https://identity.refactor.idocs.kz',
    corporateServerUrl: 'https://site.api.refactor.idocs.kz',
    isProductionMode: false,
    authenticationHost: 'https://account.refactor.idocs.kz',
    env: IdocsServerEnvironment.Refactor,
    portalUrls: new PortalUrls({
        cabinet: 'https://cabinet.refactor.idocs.kz',
        billing: 'https://billing.refactor.idocs.kz',
        sign: 'https://sign.refactor.idocs.kz',
        identity: 'https://account.refactor.idocs.kz',
        company: 'https://company.refactor.idocs.kz',
    }),
});

import 'zone.js/plugins/zone-error';
import {
    IdocsServerEnvironment,
    PortalUrls,
} from '@idocs/shared-logic/server-environment'; // Included with Angular CLI.
