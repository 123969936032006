import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import menu from '@iconify/icons-mdi/menu';
import { IconifyIcon, idocsLogo } from '@idocs/icons';
import { BaseModel, StrictBaseModelPart } from '@dev-stream/utils';

export class ToolbarConfig extends BaseModel<ToolbarConfig> {
    GetDefaultValue(): StrictBaseModelPart<ToolbarConfig> {
        return { productTitle: '' };
    }
    protected BASE_TYPE = ToolbarConfig;

    productTitle!: string;
    customLogo?: IconifyIcon | string | null;
}

@Component({
    selector: 'shared-ui-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent extends BaseComponent implements OnInit {
    sidenavToggleIcon: IconifyIcon = menu;
    idocsLogo: IconifyIcon = idocsLogo;

    @Input()
    config = new ToolbarConfig();

    @Input()
    noDrawerButton = false;

    @Output()
    sidenavToggle = new EventEmitter();

    ngOnInit(): void {}
}
