import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrossPortalDrawerNavigationComponent } from './cross-portal-drawer-navigation/cross-portal-drawer-navigation.component';
import { IconModule } from '@visurel/iconify-angular';
import { DrawerLayoutModule } from '@idocs/shared-ui/drawer-layout';

@NgModule({
    declarations: [CrossPortalDrawerNavigationComponent],
    imports: [CommonModule, IconModule, DrawerLayoutModule],
    exports: [CrossPortalDrawerNavigationComponent],
})
export class CrossPortalNavigationMenuModule {}
