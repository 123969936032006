import { Injectable } from '@angular/core';
import { BaseAuthService, SharedAccountModel } from '@idocs/shared-logic';
import { IdentityConnectorClient } from '@idocskz/identity-connector';
import * as moment from 'moment';
import { AccountPermissions } from '@idocs/api/business/models/AccountPermissions.model';

@Injectable({
    providedIn: 'root',
})
export class AuthService extends BaseAuthService {
    constructor(
        private identityConnectorClient: IdentityConnectorClient<SharedAccountModel>
    ) {
        super();
    }

    authenticate() {
        this.identityConnectorClient.authenticate({
            accountId: this.identityConnectorClient.selectedAccount?.id,
        });
    }

    isAuthenticated(): boolean {
        return (
            this.tokenResult != null &&
            moment().isBefore(moment(this.tokenResult.ExpirationDate))
        );
    }

    get tokenResult() {
        return this.identityConnectorClient.selectedAccount?.tokenResult;
    }

    switchAccount() {
        this.identityConnectorClient.authenticate();
    }

    hasAccountPermissions(accountPermission: AccountPermissions) {
        const checker = this.tokenResult?.AccountPermissions.find((perm) =>
            typeof perm === 'string'
                ? accountPermission ===
                  AccountPermissions[perm as keyof typeof AccountPermissions]
                : false
        );
        return !!checker;
    }
}
