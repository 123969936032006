import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';

@Component({
    selector: 'shared-ui-document-icon',
    templateUrl: './document-icon.component.html',
    styleUrls: ['./document-icon.component.scss'],
})
export class DocumentIconComponent extends BaseComponent implements OnInit {
    @Input()
    extension?: string | null = '';

    get extensionFirstLetter() {
        return this.extension?.[0] ?? '';
    }

    ngOnInit(): void {}
}
