import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import {
    DocumentPreviewStatusEnum,
    DocumentRibbonState,
    DocumentStatusActionEnum,
    DocumentStatusActionVM,
    DocumentStatusRibbonConfig,
} from '@idocs/shared-ui/document-preview';
import {
    approvalRibbonState,
    approvedRibbonState,
    declinedRibbonState,
    familiarizationRibbonState,
    familiarizedRibbonState,
    signedRibbonState,
    signingRibbonState,
} from '@idocs/shared-ui/document-preview/document-status-ribbon/document-status-ribbon.values';
import InfoIcon from '@iconify/icons-mdi/information-outline';

/**
 * @class DocumentStatusRibbonComponent - ui component for document status ribbon
 * @extends BaseComponent
 * @implements OnInit
 */
@Component({
    selector: 'shared-ui-document-status-ribbon',
    templateUrl: './document-status-ribbon.component.html',
    styleUrls: ['./document-status-ribbon.component.scss'],
})
export class DocumentStatusRibbonComponent
    extends BaseComponent
    implements OnInit
{
    /**
     * parameter for configuration of this ui component
     * @see {@link DocumentStatusRibbonConfig}
     * @private
     */
    private _config = new DocumentStatusRibbonConfig();

    /**
     * Information icon for status
     */
    icon = InfoIcon;

    /**
     * Setter for {@link _config}
     * after setting config, gets new ribbon state
     * @see {@link getRibbonState}
     * @param value - config param for setter {@link DocumentStatusRibbonConfig}
     */
    @Input()
    set config(value) {
        this._config = value;
        let result = this.getRibbonState(value.state);
        if (result) {
            if (value.isDynamicText) {
                result = this.setDynamicTextToState(value, result);
            }
            if (value.actionsConfig && value.actionsConfig.length > 0) {
                for (const ribbon of result.ribbons) {
                    for (const actionConfig of value.actionsConfig) {
                        if (
                            actionConfig.actionEnum ===
                            DocumentStatusActionEnum.All
                        ) {
                            ribbon.actions = ribbon.actions?.map(
                                (action) =>
                                    new DocumentStatusActionVM(action, {
                                        disabled: actionConfig.disabled,
                                        tooltip: actionConfig.tooltip,
                                    })
                            );
                        }
                    }
                }
            }

            this.ribbonState = result;
        }
    }

    /**
     * getter for {@link _config}
     */
    get config() {
        return this._config;
    }

    /**
     * Output parameter for action clicked,
     * returns {@link DocumentStatusActionVM}
     */
    @Output() actionClicked = new EventEmitter<DocumentStatusActionVM>();

    /**
     * Parameter of ribbon state.
     * @see {@link DocumentRibbonState}
     */
    ribbonState = new DocumentRibbonState();

    /**
     * Method that changes default ribbon state's first state texts to dynamic texts from config
     * @param config  - {@link DocumentStatusRibbonConfig}
     * @param ribbonState - {@link  DocumentRibbonState}
     * @returns - modified ribbon state {@link  DocumentRibbonState}
     */
    setDynamicTextToState(
        config: DocumentStatusRibbonConfig,
        ribbonState: DocumentRibbonState
    ) {
        switch (config.state) {
            case DocumentPreviewStatusEnum.Declined: {
                ribbonState.ribbons[0].declineText =
                    config?.declineReason ?? ribbonState.ribbons[0].declineText;
                break;
            }

            default: {
                ribbonState.ribbons[0].conditionalStatusText =
                    config?.conditionalStatusText ??
                    ribbonState.ribbons[0].conditionalStatusText;

                ribbonState.ribbons[0].statusInfoText =
                    config?.statusInfoText ??
                    ribbonState.ribbons[0].statusInfoText;
                break;
            }
        }

        return ribbonState;
    }

    /**
     * Array of constant values for ribbon state
     */
    ribbonStates = [
        approvalRibbonState(),
        familiarizationRibbonState(),
        signingRibbonState(),
        signedRibbonState(),
        approvedRibbonState(),
        familiarizedRibbonState(),
        declinedRibbonState(),
    ];

    /**
     * init function
     */
    ngOnInit(): void {}

    /**
     * Function for getting ribbon state from {@link ribbonStates}, using enum
     * @param value - enum for getting ribbon state. {@link DocumentPreviewStatusEnum}
     */
    getRibbonState(value: DocumentPreviewStatusEnum) {
        return this.ribbonStates.find((rs) => rs.state === value);
    }

    /**
     * Function for {@link actionClicked} to emit action
     * @param action - param of class {@link DocumentStatusActionVM}
     */
    click(action: DocumentStatusActionVM) {
        this.actionClicked.emit(action);
    }
}
