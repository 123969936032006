import { HttpClient } from '@angular/common/http';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Injector,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
    BaseModel,
    StrictBaseModelPart,
    Base64,
    eitherify,
    mapRight,
} from '@dev-stream/utils';
import { BaseComponent } from '@idocs/shared-ui/core';
import mdiAccount from '@iconify/icons-mdi/account';
import mdiLoading from '@iconify/icons-mdi/loading';
import { IconifyIcon } from '@idocs/icons';
import { from, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class ImageConfig extends BaseModel<ImageConfig> {
    protected BASE_TYPE = ImageConfig;

    GetDefaultValue(): StrictBaseModelPart<ImageConfig, never> {
        return {
            type: 'icon',
            src: mdiAccount,
        };
    }

    src!: string | IconifyIcon | null;
    type!: 'blob' | 'url' | 'base64' | 'icon';
}

@Component({
    selector: 'shared-ui-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss'],
})
export class ImageComponent extends BaseComponent implements OnInit {
    constructor(
        injector: Injector,
        private _sanitizer: DomSanitizer,
        private _http: HttpClient,
        private cdr: ChangeDetectorRef
    ) {
        super(injector);
    }

    private _config?: ImageConfig | null = new ImageConfig();

    @HostBinding('style.--icon-size.px')
    @Input()
    iconSize?: number;

    @Input()
    public set config(v: ImageConfig | null | undefined) {
        if (this._config != v) {
            this._config = v;
            this.updateImage().then();
        }
    }
    public get config(): ImageConfig | null | undefined {
        return this._config;
    }

    public imgSource?: string | SafeResourceUrl;
    public icon?: string | IconifyIcon = mdiAccount;

    @Input()
    rounded = true;

    @Input()
    plugSrc: IconifyIcon = mdiLoading;
    @Input()
    showAPlug = false;

    @Output()
    blobFailedToLoad = new EventEmitter<boolean>();

    cancellationToken = new Subject<any>();

    async updateImage() {
        delete this.imgSource;
        delete this.icon;
        this.cancellationToken.next();
        this.cancellationToken.complete();

        if (
            this.config?.type == 'base64' &&
            typeof this.config.src == 'string'
        ) {
            this.imgSource = this.config.src;
        } else if (this.config?.type == 'icon' && this.config.src) {
            this.icon = this.config.src;
        } else if (
            this.config?.type == 'url' &&
            typeof this.config.src == 'string'
        ) {
            this.imgSource = this._sanitizer.bypassSecurityTrustResourceUrl(
                this.config.src
            );
        } else if (
            this.config?.type == 'blob' &&
            typeof this.config.src == 'string'
        ) {
            this.cancellationToken = new Subject<any>();

            const blobResult = await this._http
                .get(this.config.src, { responseType: 'blob' })
                .pipe(
                    takeUntil(this.destroyed),
                    takeUntil(this.cancellationToken.asObservable()),
                    eitherify()
                )
                .toPromise();

            if (!blobResult || (blobResult && blobResult.isLeft())) {
                this.blobFailedToLoad.emit(true);
            }

            if (blobResult && blobResult.isRight()) {
                this.blobFailedToLoad.emit(false);
                await from(Base64.toBase64(blobResult.value))
                    .pipe(
                        takeUntil(this.destroyed),
                        takeUntil(this.cancellationToken.asObservable()),
                        eitherify(),
                        mapRight((image) => {
                            this.imgSource = image as string;
                            this.cdr.markForCheck();
                        })
                    )
                    .toPromise();
            }
        }
    }

    ngOnInit() {}
}
