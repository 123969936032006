<shared-ui-page pageTitle="Контакты">
    <shared-ui-content-layout class="flex-1 gap-y-48 lt-lg:gap-y-16">
        <div
            class="
                flex flex
                items-stretch
                gap-32
                flex-wrap
                col-span-9
                lt-lg:col-span-full
            "
        >
            <div
                class="
                    left-panel
                    flex flex-col flex-1
                    items-stretch
                    min-w-[300px]
                    gap-12
                "
            >
                <shared-ui-contacts-card
                    cardTitle="Техническая поддержка"
                    description="Обращайтесь с вопросами, пожеланиями или жалобами, как можно улучшить idocs."
                    [emails]="['support@idocs.kz']"
                    [phones]="['+7 727 339-03-63', '+7 700 080-44-55']"
                    appearance="raised"
                    (feedback)="openFeedbackForm('support')"
                ></shared-ui-contacts-card>

                <shared-ui-contacts-card
                    cardTitle="Продажи"
                    description="Команда по продажам ответит на все интересующие Вас вопросы по тарифам и услугам."
                    [emails]="['sales@idocs.kz']"
                    [phones]="['+7 727 339-03-63', '+7 700 080-44-55']"
                    (feedback)="openFeedbackForm('sales')"
                ></shared-ui-contacts-card>

                <shared-ui-contacts-card
                    cardTitle="Общие запросы"
                    description="Задайте вопрос, узнайте о возможностях партнерства или отправьте запрос от СМИ, заполнив форму. Мы оперативно отвечаем на сообщения."
                    [emails]="['info@idocs.kz']"
                    [phones]="['+7 727 339-03-63', '+7 700 080-44-55']"
                    (feedback)="openFeedbackForm('common')"
                ></shared-ui-contacts-card>
            </div>
            <div
                class="
                    right-panel
                    flex flex-col flex-1
                    items-stretch
                    min-w-[300px]
                "
            >
                <h4 class="font-header-5 text-header-5 mb-8">Адрес</h4>
                <p class="text-paragraph-3 font-paragraph-3 mb-12">
                    A05F0G9, г.Алматы, ул.Маметовой 54, БЦ Fifty Four Coworking
                    - 3 этаж
                </p>
                <div
                    class="
                        gmap
                        flex flex-col
                        items-stretch
                        shadow-level-2
                        overflow-hidden
                        rounded-8
                    "
                >
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1452.6431695305112!2d76.93450778407144!3d43.26637669561395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38836f2ea6990663%3A0x978042e103501350!2sFifty%20Four%20Coworking!5e0!3m2!1sen!2skz!4v1621405184081!5m2!1sen!2skz"
                        style="border: 0"
                        allowfullscreen=""
                        loading="lazy"
                    ></iframe>
                </div>
            </div>
        </div>
    </shared-ui-content-layout>
</shared-ui-page>
