<div class="viewer-wrapper flex flex-col" [ngClass]="classes">
<!--    <div class="actions">-->
<!--        <shared-ui-button-->
<!--            appearance="icon"-->
<!--            [color]="leftSideBarOpened ? 'accent' : 'neutral'"-->
<!--            (click)="toggleLeftSidebar()"-->
<!--        >-->
<!--            <ic-icon [icon]="toggleLeftSidebarIcon"></ic-icon>-->
<!--        </shared-ui-button>-->
<!--    </div>-->
    <div class="viewer flex-1" #viewer></div>
</div>
