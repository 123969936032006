<div
    class="
        toolbar
        px-18
        flex
        items-stretch
        h-58
        bg-neutral-extra-extra-lighter
        border-b border-neutral-extra-light
    "
>
    <div class="left-part flex items-center">
        <div class="menu-button-area flex items-center">
            <shared-ui-button
                class="mr-18 gt-sm:hidden"
                *ngIf="!noDrawerButton"
                appearance="icon"
                color="primary"
                (click)="sidenavToggle.emit()"
            >
                <ic-icon [icon]="sidenavToggleIcon"></ic-icon>
            </shared-ui-button>
            <ng-content select="[toolbarMenuArea]"></ng-content>
        </div>

        <div class="logo flex flex-col items-stretch gap-8 text-accent-main">
            <!--            <div-->
            <!--                class="-->
            <!--                    logo-->
            <!--                    flex-->
            <!--                    items-center-->
            <!--                    object-cover-->
            <!--                    w-100-->
            <!--                    h-[35px]-->
            <!--                    overflow-hidden-->
            <!--                "-->
            <!--            >-->
            <!--                <img-->
            <!--                    src="/assets/shared-ui/icons/xmas-logo.svg"-->
            <!--                    class="w-100"-->
            <!--                    alt=""-->
            <!--                />-->
            <!--            </div>-->
            <ng-container *ngIf="config?.customLogo">
                <div class="custom-logo">
                    <ic-icon [icon]="config.customLogo ?? ''"></ic-icon>
                </div>
            </ng-container>

            <ic-icon
                *ngIf="!config?.customLogo"
                [icon]="idocsLogo"
                width="100px"
                height="29px"
            ></ic-icon>

            <span class="product-title text-[12px] text-on-light-secondary">{{
                config.productTitle
            }}</span>
        </div>
        <ng-content select="[toolbarLeft]"></ng-content>
    </div>
    <div class="right-part ml-auto flex items-center">
        <ng-content select="[toolbarRight]"></ng-content>
    </div>
</div>
