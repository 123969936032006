import { SwaggerApiService } from './SwaggerApiService.service';
import { SwaggerApiRequestHeaders } from './SwaggerApiService.service';
import { eitherify, mapLeft, mapRight } from '@dev-stream/utils';
import { Inject, Optional, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BASE_URL, CorporateServerApiRootModule } from './CorporateServerApi.module';
import { GetInstructionsViewModel } from '../models/GetInstructionsViewModel.model';

@Injectable({ providedIn: CorporateServerApiRootModule })
export class InstructionsApiService extends SwaggerApiService {
    public constructor(http: HttpClient, @Optional() @Inject(BASE_URL) baseUrl: string) {
        super(http, baseUrl);
    }

    public Download_byStorageId_byFilename(storageId: string, filename: string, requestHeaders?: SwaggerApiRequestHeaders) {
        const response = this.http.request('get', `${this.baseUrl}/api/Instructions/Download/${storageId}/${filename}`, {
            headers: requestHeaders,
            responseType: 'text',
        });

        return response.pipe(
            eitherify(),

            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public GetInstructions<T extends Array<GetInstructionsViewModel>>(requestHeaders?: SwaggerApiRequestHeaders) {
        const response = this.http.request<T>('get', `${this.baseUrl}/api/Instructions/GetInstructions`, {
            headers: requestHeaders,
            responseType: 'json',
        });

        return response.pipe(
            eitherify(),
            mapRight((response) =>
                Array.isArray(response) ? response.map((i) => new GetInstructionsViewModel(i)) : response
            ),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }
}
