import {
    Component,
    EventEmitter,
    Injector,
    OnInit,
    Output,
} from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';

@Component({
    selector: 'company-ui-empty-feature-info',
    templateUrl: './empty-feature-info.component.html',
    styleUrls: ['./empty-feature-info.component.scss'],
})
export class EmptyFeatureInfoComponent extends BaseComponent implements OnInit {
    constructor(injector: Injector) {
        super(injector);
    }

    @Output() addFeature = new EventEmitter();

    ngOnInit(): void {}
}
