import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpPagesNavigationComponent } from './help-pages-navigation/help-pages-navigation.component';
import { DrawerLayoutModule } from '@idocs/shared-ui/drawer-layout';

@NgModule({
  declarations: [HelpPagesNavigationComponent],
  imports: [CommonModule, DrawerLayoutModule],
  exports: [
    HelpPagesNavigationComponent
  ]
})
export class HelpPagesComponentsModule {}
