import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentIconComponent } from './document-icon/document-icon.component';
import { PackageFileSelectorComponent } from './package-file-selector/package-file-selector.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { ButtonModule } from '@idocs/shared-ui/button';
import { AppIconsModule } from '@idocs/shared-ui/app-icons';
import { PdfViewerAnnotationDirective } from './pdf-viewer/pdf-viewer-annotation.directive';
import { DocumentThumbnailComponent } from './document-thumbnail/document-thumbnail.component';
import { MatRippleModule } from '@angular/material/core';
import { DocumentStatusRibbonComponent } from './document-status-ribbon/document-status-ribbon.component';
import { RibbonModule } from '@idocs/shared-ui/ribbon/ribbon.module';
import { AccountCardModule } from '@idocs/shared-ui/account-card';

@NgModule({
    declarations: [
        DocumentIconComponent,
        PackageFileSelectorComponent,
        PdfViewerComponent,
        PdfViewerAnnotationDirective,
        DocumentThumbnailComponent,
        DocumentStatusRibbonComponent,
    ],
    imports: [
        CommonModule,
        MatTooltipModule,
        RibbonModule,
        ButtonModule,
        AppIconsModule,
        AccountCardModule,
        MatRippleModule,
    ],
    exports: [
        DocumentIconComponent,
        PackageFileSelectorComponent,
        PdfViewerComponent,
        PdfViewerAnnotationDirective,
        DocumentThumbnailComponent,
        DocumentStatusRibbonComponent,
    ],
})
export class DocumentPreviewModule {}
