<mat-expansion-panel
    (opened)="panelStateOpened = true"
    (closed)="panelStateOpened = false"
>
    <mat-expansion-panel-header>
        <mat-panel-title>
            <p class="text-paragraph-2 font-paragraph-2">
                {{ instruction.Title }}
            </p>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="items-container">
        <ng-container *ngFor="let item of instruction.Items">
            <mat-card class="cursor-pointer" (click)="itemClicked(item)">
                <mat-card-content>
                    <p class="text-paragraph-3 font-paragraph-3 break-words">
                        {{ item.Title }}
                    </p>
                </mat-card-content>
            </mat-card>
        </ng-container>
    </div>
</mat-expansion-panel>
