<div
    class="cursor-pointer flex-col bg-white text-center"
    [ngClass]="{
        'border-accent-main border-solid relative z-50':
            dragging && (!config.isCustomResultContent || files.length == 0),
        'border-dashed border-neutral-lighter':
            !dragging && (!config.isCustomResultContent || files.length == 0),
        'border-2': !config.isCustomResultContent || files.length == 0,
        'hover:border-info-extra-light':
            !config.isCustomResultContent || files.length == 0,
        'pointer-events-none': disabled
    }"
>
    <div
        class="dropzone"
        [dropzone]="dropZoneConfig"
        (error)="onUploadError($event)"
        (thumbnail)="onUploadSuccess($event)"
        (dragOver)="dragging = true"
        (dragLeave)="dragging = false"
        (addedFiles)="addedFiles($event)"
    >
        <div
            *ngIf="
                (files.length == 0 || !config.isShowUploadResult) &&
                    !config.isCustomDropAreaTemplate;
                else customDropArea
            "
            class="
                drop-content
                dz-default dz-message
                flex-col
                items-center
                px-24
                py-12
                flex
                justify-center
            "
        >
            <div class="flex justify-center items-center">
                <ic-icon
                    [icon]="config.icon"
                    [width]="'50'"
                    [height]="'50'"
                    class="mr-16"
                ></ic-icon>
                <div class="text-left">
                    <div
                        class="
                            text-on-light-secondary text-paragraph-2
                            font-paragraph-2
                        "
                    >
                        {{ config.title }}
                    </div>
                    <div
                        class="
                            text-label-2
                            font-label-2
                            text-on-light-secondary
                        "
                    >
                        {{ config.description }}
                    </div>
                </div>
            </div>
        </div>
        <ng-template #customDropArea>
            <div
                *ngIf="files.length == 0 || !config.isShowUploadResult"
                class="drop-content dz-default dz-message"
            >
                <ng-content select="[drop-area]"> </ng-content>
            </div>
        </ng-template>
        <div
            class="dz-default dz-message files-content justify-start"
            *ngIf="
                files.length != 0 &&
                !config.isCustomResultContent &&
                config.isShowUploadResult
            "
        >
            <div class="flex flex-row justify-start flex-wrap">
                <ng-container *ngFor="let file of files; let index = index">
                    <div
                        class="
                            dz-preview dz-file-preview
                            p-6
                            border-2 border-solid border-info-main
                            bg-info-extra-lighter
                            rounded-6
                            m-10
                        "
                    >
                        <div class="flex flex-row justify-end">
                            <ic-icon
                                [icon]="trash"
                                [width]="'12'"
                                [height]="'12'"
                                (click)="removeFile(index, $event)"
                            ></ic-icon>
                        </div>
                        <div
                            class="
                                dz-details
                                flex flex-col
                                justify-center
                                items-center
                            "
                        >
                            <div class="file-img mb-20 shadow-level-2 bg-white">
                                <img
                                    *ngIf="file.dataURL"
                                    src="{{ file.dataURL }}"
                                    alt="thumbnail"
                                />
                            </div>
                            <div
                                class="
                                    dz-filename
                                    label
                                    whitespace-nowrap
                                    overflow-hidden overflow-ellipsis
                                    w-full
                                "
                                [matTooltip]="file.name"
                            >
                                {{ file.name }}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <ng-container
            *ngIf="
                files.length != 0 &&
                config.isCustomResultContent &&
                config.isShowUploadResult
            "
        >
            <ng-content select="[result]"> </ng-content>
        </ng-container>
    </div>
</div>
<div
    *ngIf="
        config.uploadHint && (files.length == 0 || !config.isShowUploadResult)
    "
    class="font-label-2 text-label-2 text-on-light-secondary mt-4"
>
    {{ config.uploadHint }}
</div>
<div
    *ngIf="dragging"
    class="
        bg-secondary-extra-darker
        w-full
        h-full
        fixed
        top-0
        left-0
        opacity-50
        z-100
    "
></div>
