import {
    DocumentPreviewStatusEnum,
    DocumentRibbonState,
    DocumentStatusActionEnum,
    DocumentStatusActionVM,
    DocumentStatusRibbonVM,
} from '@idocs/shared-ui/document-preview';

export const approvalRibbonState = () =>
    new DocumentRibbonState({
        ribbons: [
            new DocumentStatusRibbonVM({
                mainIcon: 'verified',
                conditionalStatusText:
                    'Вас назначили сотрудником, ответственным за согласование этого документа. Внимательно проверьте весь документ.',
                statusInfoText: 'Документ ожидает вашего согласования',
                usePersonInfo: true,
                infoTooltipText:
                    'Вам необходимо согласовать документ или отказать в его согласовании.',
                background: 'bg-neutral-lighter',
                border: 'border-l-neutral-lighter',
                isStretching: true,
            }),
            new DocumentStatusRibbonVM({
                usePersonInfo: false,
                background: 'bg-accent-extra-light',
                border: 'border-l-accent-extra-light',
                actions: [
                    new DocumentStatusActionVM({
                        actionState: DocumentStatusActionEnum.Decline_Approval,
                        title: 'Отклонить согласование',
                        theme: 'outline',
                    }),
                    new DocumentStatusActionVM({
                        actionState: DocumentStatusActionEnum.Approve,
                        title: 'Согласовать',
                        theme: 'raised',
                    }),
                ],
            }),
        ],
        state: DocumentPreviewStatusEnum.On_Approval,
    });

export const familiarizationRibbonState = () =>
    new DocumentRibbonState({
        ribbons: [
            new DocumentStatusRibbonVM({
                mainIcon: 'search',
                conditionalStatusText:
                    'Вам отправлен документ для ознакомления. Пожалуйста, ознакомьтесь с данным документом.',
                statusInfoText: 'Документ ожидает вашего ознакомления',
                usePersonInfo: true,
                infoTooltipText:
                    'Вам необходимо подтвердить ознакомленность с документом.',
                background: 'bg-neutral-lighter',
                border: 'border-l-neutral-lighter',
                isStretching: true,
            }),
            new DocumentStatusRibbonVM({
                usePersonInfo: false,
                background: 'bg-accent-extra-light',
                border: 'border-l-accent-extra-light',
                actions: [
                    new DocumentStatusActionVM({
                        actionState: DocumentStatusActionEnum.Familiarize,
                        title: 'ознакомиться',
                        theme: 'raised',
                    }),
                ],
            }),
        ],
        state: DocumentPreviewStatusEnum.On_Familiarization,
    });

export const signingRibbonState = () =>
    new DocumentRibbonState({
        ribbons: [
            new DocumentStatusRibbonVM({
                mainIcon: 'edit-file',
                conditionalStatusText:
                    'Вас назначили сотрудником, ответственным за подписание этого документа. Внимательно проверьте весь документ. Ваше утверждение документа имеет юридическую силу.',
                statusInfoText: 'Документ ожидает вашего подписания',
                usePersonInfo: true,
                infoTooltipText:
                    'Вам необходимо подписать документ или отказать в его подписании.',
                background: 'bg-neutral-lighter',
                border: 'border-l-neutral-lighter',
                isStretching: true,
            }),
            new DocumentStatusRibbonVM({
                usePersonInfo: false,
                background: 'bg-accent-extra-light',
                border: 'border-l-accent-extra-light',
                actions: [
                    new DocumentStatusActionVM({
                        actionState: DocumentStatusActionEnum.Decline_Signing,
                        title: 'Отклонить подписание',
                        theme: 'outline',
                    }),
                    new DocumentStatusActionVM({
                        actionState: DocumentStatusActionEnum.Sign,
                        title: 'подписать',
                        theme: 'raised',
                    }),
                ],
            }),
        ],
        state: DocumentPreviewStatusEnum.On_Signing,
    });

export const signedRibbonState = () =>
    new DocumentRibbonState({
        ribbons: [
            new DocumentStatusRibbonVM({
                mainIcon: 'check',
                conditionalStatusText:
                    'Вас назначили сотрудником, ответственным за подписание этого документа. Внимательно проверьте весь документ. Ваше утверждение документа имеет юридическую силу.',
                statusInfoText: 'Вы подписали документ',
                usePersonInfo: true,
                background: 'bg-success-main',
                border: 'border-l-success-main',
                isStretching: true,
            }),
            new DocumentStatusRibbonVM({
                usePersonInfo: false,
                background: 'bg-success-main',
                border: 'border-l-success-main',
                statusTitle: 'ПОДПИСАНО',
            }),
        ],
        state: DocumentPreviewStatusEnum.Signed,
    });

export const approvedRibbonState = () =>
    new DocumentRibbonState({
        ribbons: [
            new DocumentStatusRibbonVM({
                mainIcon: 'check',
                conditionalStatusText:
                    'Вас назначили сотрудником, ответственным за согласование этого документа. Внимательно проверьте весь документ.',
                statusInfoText: 'Вы согласовали документ',
                usePersonInfo: true,
                background: 'bg-success-main',
                border: 'border-l-success-main',
                isStretching: true,
            }),
            new DocumentStatusRibbonVM({
                usePersonInfo: false,
                background: 'bg-success-main',
                border: 'border-l-success-main',
                statusTitle: 'Согласовано',
            }),
        ],
        state: DocumentPreviewStatusEnum.Approved,
    });

export const familiarizedRibbonState = () =>
    new DocumentRibbonState({
        ribbons: [
            new DocumentStatusRibbonVM({
                mainIcon: 'check',
                conditionalStatusText:
                    'Вам отправлен документ для ознакомления. Пожалуйста, ознакомьтесь с данным документом.',
                statusInfoText: 'Вы ознакомились с документом',
                usePersonInfo: true,
                background: 'bg-success-main',
                border: 'border-l-success-main',
                isStretching: true,
            }),
            new DocumentStatusRibbonVM({
                usePersonInfo: false,
                background: 'bg-success-main',
                border: 'border-l-success-main',
                statusTitle: 'Ознакомлен',
            }),
        ],
        state: DocumentPreviewStatusEnum.Familiarized,
    });

export const declinedRibbonState = () =>
    new DocumentRibbonState({
        ribbons: [
            new DocumentStatusRibbonVM({
                mainIcon: 'cancel',
                statusInfoText: 'Вы отклонили документ',
                declineText:
                    'В документе большое множество орфографических ошибок.',
                usePersonInfo: true,
                background: 'bg-danger-dark',
                border: 'border-l-danger-dark',
                isStretching: true,
            }),
            new DocumentStatusRibbonVM({
                usePersonInfo: false,
                background: 'bg-danger-dark',
                border: 'border-l-danger-dark',
                statusTitle: 'Отклонено',
            }),
        ],
        state: DocumentPreviewStatusEnum.Declined,
    });
