import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';

import up from '@iconify/icons-mdi/thumb-up';
import upOutline from '@iconify/icons-mdi/thumb-up-outline';
import down from '@iconify/icons-mdi/thumb-down';
import downOutline from '@iconify/icons-mdi/thumb-down-outline';

@Component({
    selector: 'shared-ui-rating-card',
    templateUrl: './rating-card.component.html',
    styleUrls: ['./rating-card.component.scss'],
})
export class RatingCardComponent extends BaseComponent implements OnInit {
    constructor(injector: Injector) {
        super(injector);
    }
    @Input() likeCount = 0;
    @Input() dislikeCount = 0;
    @Input() liked = false;
    @Input() disliked = false;

    @Output() likeClick = new EventEmitter();
    @Output() dislikeClick = new EventEmitter();

    likeIcon = up;
    likeActiveIcon = upOutline;
    dislikeIcon = down;
    dislikeActiveIcon = downOutline;

    ngOnInit(): void {}

    like() {
        if (this.liked) return;
        this.likeClick.emit();
    }

    dislike() {
        if (this.disliked) return;
        this.dislikeClick.emit();
    }
}
