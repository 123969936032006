import {
    Component,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import { BreadcrumbsService } from '../../services/breadcrumbs.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'shared-ui-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss'],
})
export class PageComponent
    extends BaseComponent
    implements OnInit, OnChanges, OnDestroy
{
    constructor(
        injector: Injector,
        @Optional() private service: BreadcrumbsService | null,
        private activatedRoute: ActivatedRoute
    ) {
        super(injector);
    }

    @ViewChild('breadcrumbsRightTemplate', { static: true })
    breadcrumbsRightTemplate!: TemplateRef<any>;

    @Input()
    pageTitle?: string;

    @Input()
    overwriteBreadcrumbItemText?: string;

    @Input()
    isDisableNavigation?: boolean;

    @Input()
    pageSubtitle?: string;

    get breadcrumbItemText() {
        return this.overwriteBreadcrumbItemText ?? this.pageTitle;
    }

    get url() {
        return this.activatedRoute.snapshot.url.toString();
    }

    ngOnInit(): void {
        this.service?.addPage(this);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.service?.removePage(this);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.service?.update();
    }
}
