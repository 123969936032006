import { BaseModel, StrictBaseModelPart } from '@dev-stream/utils';

export class PersonInfo extends BaseModel<
    PersonInfo,
    'firstNameInitial' | 'givenNameInitial'
> {
    protected BASE_TYPE = PersonInfo;

    GetDefaultValue(): StrictBaseModelPart<
        PersonInfo,
        'firstNameInitial' | 'givenNameInitial'
    > {
        return {
            imageInBase64: null,
            firstName: null,
            givenName: null,
            lastName: null,
            position: null,
            id: null,
        };
    }

    id!: string | null;
    firstName!: string | null;
    lastName!: string | null;
    givenName!: string | null;

    imageInBase64!: string | null;

    position!: string | null;

    get firstNameInitial() {
        return this.firstName && this.firstName?.[0]
            ? this.firstName[0] + '.'
            : '';
    }
    get givenNameInitial() {
        return this.givenName && this.givenName?.[0]
            ? this.givenName[0] + '.'
            : '';
    }
}

export class CompanyInfo extends BaseModel<CompanyInfo> {
    protected BASE_TYPE = CompanyInfo;

    GetDefaultValue(): StrictBaseModelPart<CompanyInfo, never> {
        return {
            imageInBase64: null,
            name: null,
        };
    }

    name!: string | null;
    imageInBase64!: string | null;
}
