import { ApplicationNewFeatureDTOApplicationWithAttachmentsViewModel } from './ApplicationNewFeatureDTOApplicationWithAttachmentsViewModel.model';
import { BaseModel, StrictBaseModelPart, Property, hasUnionKeys } from '@dev-stream/utils';

export class ApplicationNewFeatureDTOApplicationWithAttachmentsViewModelApiSuccessResponseModel extends BaseModel<ApplicationNewFeatureDTOApplicationWithAttachmentsViewModelApiSuccessResponseModel> {
    protected BASE_TYPE = ApplicationNewFeatureDTOApplicationWithAttachmentsViewModelApiSuccessResponseModel;
    'Header'!: string | null;
    'Description'!: string | null;
    @Property(() => ApplicationNewFeatureDTOApplicationWithAttachmentsViewModel)
    'Payload'!: ApplicationNewFeatureDTOApplicationWithAttachmentsViewModel | null;

    public GetDefaultValue(): StrictBaseModelPart<ApplicationNewFeatureDTOApplicationWithAttachmentsViewModelApiSuccessResponseModel> {
        return {
            Header: null,
            Description: null,
            Payload: null,
        };
    }

    public static isApplicationNewFeatureDTOApplicationWithAttachmentsViewModelApiSuccessResponseModel(
        o: any
    ): o is ApplicationNewFeatureDTOApplicationWithAttachmentsViewModelApiSuccessResponseModel {
        return (
            o instanceof ApplicationNewFeatureDTOApplicationWithAttachmentsViewModelApiSuccessResponseModel ||
            hasUnionKeys(o, ['Header', 'Description', 'Payload'])
        );
    }
}
