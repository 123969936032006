<div class="flex select-none" [ngClass]="classes" [matTooltip]="tooltip ?? ''">
    <div
        class="flex flex-col flex-1 rounded-4"
        [ngClass]="{ 'shadow-level-2': isShaded, shimmer: (isLoading | async) }"
        [class.bg-white]="!disabled"
        [class.bg-neutral-extra-light]="!!disabled"
    >
        <div class="flex items-center">
            <div
                (click)="!disabled && selected.emit()"
                class="flex flex-1 items-center p-4 h-full"
                [class.pr-62]="personInfo && companyInfo"
                [class.-mr-50]="personInfo && companyInfo"
                [class.cursor-pointer]="hasSubscribers && !disabled"
                [class.cursor-not-allowed]="!!disabled"
                matRipple
                [matRippleDisabled]="!hasSubscribers || !!disabled"
            >
                <div
                    *ngIf="personInfo"
                    class="person-info-image flex-none w-48 h-48 m-4 mr-12"
                >
                    <shared-ui-initials-image
                        *ngIf="
                            !personInfo.imageInBase64 || imageFailedToLoad;
                            else personInfoImageTemplate
                        "
                        [disabled]="disabled"
                        [loading]="loading"
                        class="rounded-full text-[24px]"
                        text="{{ personInfo.lastName?.[0] ?? '' }}{{ personInfo.firstName?.[0] ?? ''}}"
                    ></shared-ui-initials-image>
                    <ng-template #personInfoImageTemplate>
                        <shared-ui-image
                            [config]="personImageConfig"
                            (blobFailedToLoad)="imageFailedToLoad = $event"
                        ></shared-ui-image>
                    </ng-template>
                </div>
                <div
                    *ngIf="companyInfo && !personInfo"
                    class="
                        company-info-image
                        flex-none flex
                        items-center
                        justify-center
                        w-56
                        h-56
                        mr-12
                        rounded-4
                        shadow-level-2
                        bg-neutral-extra-lighter
                        border-2 border-solid border-white
                    "
                >
                    <div class="text-[36px] text-neutral-lighter">
                        <shared-ui-image
                            [rounded]="false"
                            [config]="companyImageConfig"
                            [iconSize]="36"
                            [plugSrc]="companyIcon"
                        ></shared-ui-image>
                    </div>

                    <!--            <shared-ui-initials-image-->
                    <!--                class=""-->
                    <!--                text="{{ companyInfo.name?.[0] ?? '' }}{{ companyInfo.name?.[1] ?? ''}}"-->
                    <!--            ></shared-ui-initials-image>-->
                </div>

                <div
                    class="
                        person-info
                        self-stretch
                        flex flex-col flex-1
                        items-stretch
                        justify-center
                    "
                >
                    <span
                        class="
                            text-on-light-base text-paragraph-3
                            font-paragraph-3
                        "
                        [ngClass]="{ 'text-neutral-main': disabled }"
                    >
                        <span [class.text-neutral-main]="!!disabled">
                            <ng-container *ngIf="personInfo">
                                {{ personInfo.lastName }}
                                {{ personInfo.firstName }}
                                {{ personInfo.givenName }}</ng-container
                            >
                            <ng-container *ngIf="companyInfo && !personInfo">{{
                                companyInfo.name
                            }}</ng-container>
                        </span>
                    </span>
                    <span
                        class="text-on-light-disabled text-label-2 font-label-2"
                        *ngIf="personInfo?.position"
                        [matTooltip]="positionTooltip"
                    >
                        <span [class.text-neutral-main]="!!disabled">
                            {{ personInfo?.position ?? '' | truncate: [138] }}
                        </span>
                    </span>
                </div>
            </div>

            <div
                *ngIf="personInfo && companyInfo"
                class="
                    flex flex-col flex-none
                    z-10
                    items-center
                    justify-center
                    shadow-level-2
                    p-4
                    w-100
                    h-100
                    rounded-4
                    -mr-18
                    -my-4
                "
                [class.bg-white]="!disabled"
                [class.bg-neutral-extra-light]="!!disabled"
            >
                <div
                    class="
                        flex-none flex
                        items-center
                        justify-center
                        w-56
                        h-56
                        mb-6
                        rounded-4
                        bg-neutral-extra-lighter
                        border-2 border-solid border-white
                    "
                    [class.shadow-level-2]="!disabled"
                >
                    <div class="text-[36px] text-neutral-lighter">
                        <shared-ui-image
                            [rounded]="false"
                            [config]="companyImageConfig"
                            [iconSize]="36"
                            [plugSrc]="companyIcon"
                        ></shared-ui-image>
                        <!--                        <ic-icon [icon]="companyIcon"></ic-icon>-->
                    </div>
                </div>

                <div
                    class="
                        text-on-light-base text-center text-label-3
                        font-label-3
                        line-clamp-2
                    "
                >
                    <span [class.text-neutral-main]="!!disabled">
                        {{ companyInfo.name }}
                    </span>
                </div>
            </div>
        </div>
        <ng-content select="shared-ui-account-card-content"></ng-content>
    </div>
</div>
