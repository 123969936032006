<div class="rating-container flex gap-14">
    <div class="flex cursor-pointer" (click)="like()">
        <ic-icon
            [size]="'20px'"
            [ngClass]="{
                'text-accent-main': liked,
                'text-neutral-main': !liked
            }"
            [icon]="liked ? likeIcon : likeActiveIcon"
        ></ic-icon>
        <p
            class="
                font-paragraph-2
                text-paragraph-2
                font-bold
                ml-6
                cursor-pointer
            "
            [ngClass]="{
                'text-accent-main': liked,
                'text-neutral-main': !liked
            }"
        >
            {{ likeCount }}
        </p>
    </div>

    <div class="flex cursor-pointer" (click)="dislike()">
        <ic-icon
            [ngClass]="{
                'text-danger-main': disliked,
                'text-neutral-main': !disliked
            }"
            [size]="'20px'"
            [icon]="disliked ? dislikeIcon : dislikeActiveIcon"
        ></ic-icon>
        <p
            class="font-paragraph-2 text-paragraph-2 font-bold ml-6"
            [ngClass]="{
                'text-danger-main': disliked,
                'text-neutral-main': !disliked
            }"
        >
            {{ dislikeCount }}
        </p>
    </div>
</div>
