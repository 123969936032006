import { BaseModel, StrictBaseModelPart, hasUnionKeys } from '@dev-stream/utils';

export class NewFeatureRatingsCount extends BaseModel<NewFeatureRatingsCount> {
    protected BASE_TYPE = NewFeatureRatingsCount;
    'Likes'!: number | null;
    'Dislikes'!: number | null;

    public GetDefaultValue(): StrictBaseModelPart<NewFeatureRatingsCount> {
        return {
            Likes: null,
            Dislikes: null,
        };
    }

    public static isNewFeatureRatingsCount(o: any): o is NewFeatureRatingsCount {
        return o instanceof NewFeatureRatingsCount || hasUnionKeys(o, ['Likes', 'Dislikes']);
    }
}
