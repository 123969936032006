<div
    class="
        support
        p-18
        flex flex-col
        items-stretch
        shadow-level-2 rounded-8
        {{ classes }}
    "
    [ngClass]="{
        'bg-accent-dark text-on-dark-base ': appearance == 'raised',
        'bg-white ': appearance != 'raised'
    }"
>
    <h4 class="font-header-5 text-header-5 mb-8">{{ cardTitle }}</h4>
    <p class="text-paragraph-3 font-paragraph-3 mb-12">
        {{ description }}
    </p>
    <div class="flex gap-24 flex-wrap mb-12">
        <div class="flex flex-col flex-1">
            <h5
                class="text-label-2 font-label-2 mb-4"
                [ngClass]="{
                    'text-on-dark-secondary': appearance == 'raised',
                    'text-on-light-secondary': appearance == 'default'
                }"
            >
                Электронный адрес
            </h5>
            <div class="flex flex-1 flex-wrap gap-8">
                <a
                    *ngFor="let email of emails"
                    [href]="'mailto:' + email"
                    class="text-paragraph-3 font-paragraph-3 underline"
                    [class.text-on-light-info]="appearance == 'default'"
                >
                    {{ email }}
                </a>
            </div>
        </div>
        <div class="flex max-w-[30ch] flex-1 flex-col">
            <h5
                class="text-label-2 font-label-2 mb-4"
                [ngClass]="{
                    'text-on-dark-secondary': appearance == 'raised',
                    'text-on-light-secondary': appearance == 'default'
                }"
            >
                Телефоны
            </h5>
            <div class="flex flex-1 flex-wrap gap-8">
                <p
                    *ngFor="let phone of phones"
                    class="text-paragraph-3 font-paragraph-3 whitespace-nowrap"
                >
                    {{ phone }}
                </p>
            </div>
        </div>
    </div>
    <shared-ui-button color="white" (click)="feedback.emit()"
        >ОСТАВИТЬ ЗАЯВКУ</shared-ui-button
    >
</div>
