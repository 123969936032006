import { Injectable } from '@angular/core';
import {
    AuthenticatedIdentityConnectorAction,
    IAuthenticationHandler,
} from '@idocskz/identity-connector';
import { SharedAccountModel } from '@idocs/shared-logic';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class DocumentsAuthenticationHandler
    implements IAuthenticationHandler<SharedAccountModel>
{
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    async handle(
        action: AuthenticatedIdentityConnectorAction<SharedAccountModel>
    ) {
        const queryParams = this.activatedRoute.snapshot.queryParams;
        const redirectTo = queryParams.redirectTo;
        if (redirectTo) {
            await this.router.navigateByUrl(redirectTo);
        } else {
            await this.router.navigateByUrl('/');
        }
    }
}
