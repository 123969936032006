<shared-ui-page pageTitle="Инструкции">
    <div *ngFor="let instruction of instructions; let i = index">
        <shared-ui-instructions-list
            [instruction]="instruction"
            (clicked)="download($event)"
        ></shared-ui-instructions-list>

        <hr
            *ngIf="i < instructions.length - 1"
            class="line text-neutral-extra-light"
        />
    </div>
</shared-ui-page>
