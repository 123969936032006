import { Component, OnInit } from '@angular/core';
import { BaseAccountCardComponent } from './../account-card.base-component';

/**
 * Small appearance of [BaseAccountCardComponent]{@link BaseAccountCardComponent}
 *
 * ```html
 * <shared-ui-account-card
 *             class="max-w-max ml-auto min-w-200"
 *             (selected)="trigger.openMenu()"
 *             [companyInfo]="companyInfo"
 *             [personInfo]="personInfo"
 * ></shared-ui-account-card>
 * ```
 * <example-url>https://raw.githubusercontent.com/angular/angular/master/README.md</example-url>
 */
@Component({
    selector: 'shared-ui-account-card',
    templateUrl: './account-card.component.html',
    styleUrls: ['./account-card.component.scss'],
})
export class AccountCardComponent
    extends BaseAccountCardComponent
    implements OnInit
{
    ngOnInit() {}
}
