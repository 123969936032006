import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from './components/page/page.component';
import { BreadcrumbsHostComponent } from './components/breadcrumbs-host/breadcrumbs-host.component';
import { IconModule } from '@visurel/iconify-angular';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [PageComponent, BreadcrumbsHostComponent],
    imports: [CommonModule, IconModule, RouterModule],
    exports: [BreadcrumbsHostComponent, PageComponent],
})
export class BreadcrumbsModule {}
