import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { DocumentsAppRoutingModule } from './documents-app-routing.module';
import { DocumentsAppComponent } from './documents-app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NotificationModule } from '@idocs/shared-ui/notification';
import { AppIconsModule } from '@idocs/shared-ui/app-icons';
import { NgxMaskModule } from 'ngx-mask';
import { BreadcrumbsModule } from '@idocs/shared-ui/breadcrumbs';
import { ErrorPagesModule } from '@idocs/shared-ui/error-pages';
import { DialogsModule } from '@idocs/shared-ui/dialogs';
import { BladeModule } from '@idocs/shared-ui/blade';
import { SharedAccountModel, SharedAuthModule } from '@idocs/shared-logic';
import { IdentityConnectorModule } from '@idocskz/identity-connector';
import { DocumentsAuthenticationHandler } from './auth/authenticationHandler';
import { TokenObtainer } from './auth/token.obtainer';
import { environment } from '../environments/environment';
import { NgAuthorizeModule } from 'ng-authorize';
import { DOCUMENTS_POLICIES } from './auth/auth.policies';
import { DocumentsAppComponentsModule } from './documents-app-components/documents-app-components.module';
import { BusinessServerApiModule } from '@idocs/api/business/services/BusinessServerApi.module';
import { NgNcalayerModule } from 'ng-ncalayer';

@NgModule({
    declarations: [DocumentsAppComponent],
    imports: [
        BrowserModule,
        DocumentsAppRoutingModule,
        DocumentsAppComponentsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NotificationModule.forRoot(),
        AppIconsModule.forRoot({}),
        NgxMaskModule.forRoot(),
        BreadcrumbsModule,
        ErrorPagesModule.forRoot(),
        DialogsModule.forRoot(),
        BladeModule.forRoot(),
        BusinessServerApiModule.forRoot(`${environment.apiServerUrl}`),
        NgAuthorizeModule.forRoot(DOCUMENTS_POLICIES),
        SharedAuthModule.forRoot({
            tokenObtainer: TokenObtainer,
        }),
        NgNcalayerModule.forRoot(),
        IdentityConnectorModule.client({
            identityUrl: environment.authenticationHost,
            accountClass: () => SharedAccountModel,
            authenticationHandler: DocumentsAuthenticationHandler,
        }),
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: 'ru-RU',
        },
    ],
    bootstrap: [DocumentsAppComponent],
})
export class DocumentsAppModule {}
