import { Directive, Input } from '@angular/core';

@Directive({
    selector: '[sharedUiPdfViewerAnnotation]',
})
export class PdfViewerAnnotationDirective {
    @Input() text?: string;
    @Input() color?: string;
    @Input() opacity?: number;
    @Input() fontSize?: number;
    @Input() fontFamily?: string;

    
}
