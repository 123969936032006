import { BaseModel, StrictBaseModelPart, hasUnionKeys } from '@dev-stream/utils';

export class Applications_PostSupport_FormData extends BaseModel<Applications_PostSupport_FormData> {
    protected BASE_TYPE = Applications_PostSupport_FormData;
    'Data.FullName'!: string | null;
    'Data.Email'!: string | null;
    'Data.CompanyName'!: string | null;
    'Data.Message'!: string | null;
    'Data.PhoneNumber'!: string | null;
    'Files'!: Array<Blob | null>;

    public GetDefaultValue(): StrictBaseModelPart<Applications_PostSupport_FormData> {
        return {
            'Data.FullName': null,
            'Data.Email': null,
            'Data.CompanyName': null,
            'Data.Message': null,
            'Data.PhoneNumber': null,
            Files: [],
        };
    }

    public static isApplications_PostSupport_FormData(o: any): o is Applications_PostSupport_FormData {
        return (
            o instanceof Applications_PostSupport_FormData ||
            hasUnionKeys(o, ['Data.FullName', 'Data.Email', 'Data.CompanyName', 'Data.Message', 'Data.PhoneNumber', 'Files'])
        );
    }
}
