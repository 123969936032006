import { BaseModel, StrictBaseModelPart } from '@dev-stream/utils';
import { PageComponent } from './components/page/page.component';
import { TemplateRef } from '@angular/core';

export class PageVm extends BaseModel<PageVm> {
    protected BASE_TYPE = PageVm;

    GetDefaultValue(): StrictBaseModelPart<PageVm, never> {
        return {
            title: 'default page title',
        };
    }

    title?: string;
    subTitle?: string;
    url?: string;
    breadcrumbsRightTemplate?: TemplateRef<any>;
    breadcrumbItemText?: string;
    isDisableNavigation?: boolean;

    static mapFromPageComponent(pageComponent: PageComponent) {
        return new PageVm({
            title: pageComponent.pageTitle,
            subTitle: pageComponent.pageSubtitle,
            url: pageComponent.url,
            breadcrumbsRightTemplate: pageComponent.breadcrumbsRightTemplate,
            breadcrumbItemText: pageComponent.breadcrumbItemText,
            isDisableNavigation: pageComponent.isDisableNavigation,
        });
    }
}
