<shared-ui-page pageTitle="Загрузки">
    <h2 class="text-header-5 font-header-5 text-neutral-extra-darker pb-16">
        Модуль 1С
    </h2>
    <div class="items items-start flex-wrap gap-16">
        <div
            class="
                item
                flex flex-col
                gap-16
                items-stretch
                w-[350px]
                max-w-[100%]
            "
        >
            <p>
                Модуль интеграции с 1С. Скачайте модуль для работы с документами
                в 1С.
            </p>
            <shared-ui-button (click)="download()"
                >СКАЧАТЬ МОДУЛЬ</shared-ui-button
            >
        </div>
    </div>

    <h2
        class="
            text-header-5
            font-header-5
            text-neutral-extra-darker
            pb-16
            mt-48
        "
    >
        Модуль 1С ЗУП
    </h2>
    <div class="items items-start flex-wrap gap-16">
        <div
            class="
                item
                flex flex-col
                gap-16
                items-stretch
                w-[350px]
                max-w-[100%]
            "
        >
            <p>
                Модуль интеграции с 1С ЗУП. Скачайте модуль для работы с
                внутренними документами в 1С ЗУП.
            </p>
            <shared-ui-button (click)="downloadZup()"
                >СКАЧАТЬ МОДУЛЬ</shared-ui-button
            >
        </div>
    </div>
</shared-ui-page>
