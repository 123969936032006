/**
 * @description
 * RegEx pattern for validating email.
 * The pattern is based on the definition of a valid email address in IDocs System
 * Valid email is:
 * - Must have @
 * - Must have a dot after @
 * - Must have at least 2 symbols and less than 11 symbols after dot
 */
export const emailRegEx = '^[_.0-9a-zA-Z-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,10}$';
