import {
    AfterContentInit,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
} from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import WebViewer, { IWebViewer } from '@pdftron/pdfjs-express-viewer';
import { environment } from '../../../../../../../applications/email-signing/src/environments/environment';
import { IconifyIcon } from '@idocs/icons';
import mdiDockLeft from '@iconify/icons-mdi/dock-left';
import { BreakpointObserver } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs/operators';
import { AppBreakpoints } from '@idocs/shared-ui/models';
import { PdfViewerAnnotationDirective } from '@idocs/shared-ui/document-preview/pdf-viewer/pdf-viewer-annotation.directive';

@Component({
    selector: 'shared-ui-pdf-viewer',
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent
    extends BaseComponent
    implements OnInit, AfterContentInit
{
    constructor(
        injector: Injector,
        private breakpointObserver: BreakpointObserver
    ) {
        super(injector);
    }

    @ContentChildren(PdfViewerAnnotationDirective)
    annotationsQuery?: QueryList<PdfViewerAnnotationDirective>;

    @ViewChild('viewer', { static: true })
    viewer!: ElementRef<HTMLDivElement>;

    @Output()
    download = new EventEmitter();

    private isDownloading = false;

    public get downloading() {
        return this.isDownloading;
    }
    @Input()
    public set downloading(v: boolean | null | undefined) {
        this.isDownloading = !!v;
    }

    viewerInstance?: IWebViewer;

    getViewerInstance() {
        if (this.viewerInstance) {
            return Promise.resolve(this.viewerInstance);
        } else {
            return this.createViewer();
        }
    }

    private url: string = '';
    @Input() set documentUrl(url: string) {
        this.url = url;
        console.log(url);
        this.getViewerInstance().then((i) => {
            i.UI.loadDocument(url, {
                filename: `document.pdf`,
            });
        });
    }

    async ngOnInit() {
        this.viewerInstance = await this.createViewer();
        this.breakpointObserver
            .observe(AppBreakpoints.LT_MD)
            .pipe(takeUntil(this.destroyed))
            .subscribe((state) => this.toggleMobileActions(state.matches));
    }

    async ngAfterContentInit() {
        if (!this.annotationsQuery) {
            return;
        }
        this.annotationsQuery.changes
            .pipe(takeUntil(this.destroyed))
            .subscribe(this.addAnnotations.bind(this));
        await this.addAnnotations();
    }

    private async addAnnotations() {
        const annotations = this.annotationsQuery?.toArray() ?? [];
        const instance = await this.getViewerInstance();
        annotations.forEach((annotation) => {
            instance.Core.documentViewer.setWatermark({
                diagonal: {
                    text: annotation.text,
                    color: annotation.color,
                    opacity: annotation.opacity,
                    fontSize: annotation.fontSize,
                    fontFamily: 'sans-serif',
                },
            });
        });
    }

    creatingViewer?: Promise<IWebViewer>;
    toggleLeftSidebarIcon: IconifyIcon = mdiDockLeft;

    private createViewer() {
        if (this.creatingViewer) {
            return this.creatingViewer;
        }
        this.creatingViewer = WebViewer(
            {
                path: '/pdfjs', // point to where the files you copied are served from
                // initialDoc: `https://api.refactor.idocs.kz/api/v2/fact-document-files/download-file(${token}).pdf`, // path to your document
                licenseKey: environment.pdfViewerLicenceKey,
                css: '/pdfjs/pdfjs-styles.css',
            },
            this.viewer?.nativeElement
        ).then((instance: IWebViewer) => {
            this.customizeViewer(instance);
            delete this.creatingViewer;
            return instance;
        });
        return this.creatingViewer;
    }

    private customizeViewer(instance: IWebViewer) {
        instance.UI.setLanguage('ru');
        const Feature = instance.UI.Feature;
        instance.UI.disableFeatures([Feature.Copy]);
        const style = instance.UI.iframeWindow.document.documentElement.style;
        style.setProperty(
            `--document-background-color`,
            'rgba(238, 238, 238, 1)'
        );
        style.setProperty('--view-header-button-hover', '#91DCBE');
        style.setProperty('--view-header-button-active', '#BDEAD8');
        style.setProperty('--view-header-icon-active-fill', '#087A4B');
        this.customizeViewerHeader(instance);
    }

    private customizeViewerHeader(instance: IWebViewer) {
        instance.UI.disableElements([
            'selectToolButton',
            'contextMenuPopup',
            'themeChangeButton',
            'printButton',
            'menuButton',
            'moreButton',
            'leftPanelResizeBar',
        ]);
        instance.UI.setHeaderItems((header) => {
            const items = header.getItems();
            const dividerIndex = items.findIndex((i) => i.type == 'divider');
            if (dividerIndex >= 0) {
                items.splice(dividerIndex, 1);
            }
            const searchButton = items.find(
                (i) => i.dataElement == 'searchButton'
            );
            if (searchButton) {
                searchButton.hidden = [];
            }
            items.push({
                type: 'actionButton',
                img: 'icon-download',
                dataElement: 'downloadAction',
                onClick: () => {
                    if (this.downloading) {
                        return;
                    }
                    this.download.emit();
                },
            });
            header.update(items);
        });

        instance.setFitMode(instance.FitMode.FitWidth);
    }

    private async toggleMobileActions(isLtMd: boolean) {
        const instance = await this.getViewerInstance();
        if (isLtMd) {
            instance.UI.enableElements(['downloadAction']);
            instance.UI.disableElements(['leftPanelButton']);
        } else {
            instance.UI.disableElements(['downloadAction']);
            instance.UI.enableElements(['leftPanelButton']);
        }
    }
}
