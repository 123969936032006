import { Injectable, Optional, SkipSelf } from '@angular/core';
import { PageComponent } from '../components/page/page.component';
import { ReplaySubject } from 'rxjs';
import { remove } from 'lodash';

@Injectable()
export class BreadcrumbsService {
    readonly id: number;
    static ID = 0;

    private pages: PageComponent[] = [];

    private renderPagesSubject = new ReplaySubject<PageComponent[]>();
    public renderPages$ = this.renderPagesSubject.asObservable();

    constructor(@Optional() @SkipSelf() private parent: BreadcrumbsService) {
        this.id = BreadcrumbsService.ID++;
        if (parent) {
            console.log('found parent', parent.id);
        }
        console.log('initialized self', this.id);
    }

    addPage(page: PageComponent) {
        this.pages.push(page);
        this.update();
    }

    update() {
        this.renderPagesSubject.next(this.pages);
    }

    removePage(page: PageComponent) {
        remove(this.pages, (p) => p == page);
        this.update();
    }
}
