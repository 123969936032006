import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountCardComponent } from './account-card/account-card.component';
import { AccountCardLargeComponent } from './account-card-large/account-card-large.component';
import { InitialsImageComponent } from './initials-image/initials-image.component';
import { MatRippleModule } from '@angular/material/core';
import { AccountCardContentComponent } from './account-card-content/account-card-content.component';
import { AppIconsModule } from '@idocs/shared-ui/app-icons';
import { AccountCardStatusComponent } from './account-card-status/account-card-status.component';
import { ImageModule } from '@idocs/shared-ui/image';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TruncatePipe } from '@idocs/shared-ui/pipes/truncate.pipe';

@NgModule({
    declarations: [
        AccountCardComponent,
        AccountCardLargeComponent,
        InitialsImageComponent,
        AccountCardContentComponent,
        AccountCardStatusComponent,
        TruncatePipe,
    ],
    imports: [
        CommonModule,
        MatRippleModule,
        AppIconsModule,
        ImageModule,
        MatTooltipModule,
    ],
    exports: [
        AccountCardComponent,
        AccountCardLargeComponent,
        AccountCardContentComponent,
        AccountCardStatusComponent,
    ],
})
export class AccountCardModule {}
