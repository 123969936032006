import { BaseModel, StrictBaseModelPart } from '@dev-stream/utils';

export class FeatureInfoVm extends BaseModel<FeatureInfoVm> {
    protected BASE_TYPE = FeatureInfoVm;

    GetDefaultValue(): StrictBaseModelPart<FeatureInfoVm, never> {
        return {
            ApplicationId: '',
            Title: '',
            Content: '',
            LikeCount: 0,
            DislikeCount: 0,
            Disliked: false,
            Liked: false,
        };
    }

    ApplicationId!: string;
    Title!: string;
    Content!: string;
    Status?: string;
    LikeCount!: number;
    DislikeCount!: number;
    Liked!: boolean;
    Disliked!: boolean;
}
