import { BaseModel, StrictBaseModelPart } from '@dev-stream/utils';

export class FeatureInfoFormVm extends BaseModel<FeatureInfoFormVm> {
    protected BASE_TYPE = FeatureInfoFormVm;

    Title!: string;
    Content!: string;
    Files?: File[];
    IsInternal!: boolean;
    IsExternal!: boolean;

    GetDefaultValue(): StrictBaseModelPart<FeatureInfoFormVm, never> {
        return {
            Title: '',
            Content: '',
            IsInternal: false,
            IsExternal: false,
        };
    }
}
