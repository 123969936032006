import { SwaggerApiService } from './SwaggerApiService.service';
import { SwaggerApiRequestHeaders } from './SwaggerApiService.service';
import { eitherify, mapLeft, mapRight } from '@dev-stream/utils';
import { Inject, Optional, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BASE_URL, CorporateServerApiRootModule } from './CorporateServerApi.module';
import { Applications_PostSupport_FormData } from '../models/Applications_PostSupport_FormData.model';
import { BooleanApiSuccessResponseModel } from '../models/BooleanApiSuccessResponseModel.model';
import { Applications_PostSales_FormData } from '../models/Applications_PostSales_FormData.model';
import { Applications_PostCommon_FormData } from '../models/Applications_PostCommon_FormData.model';

@Injectable({ providedIn: CorporateServerApiRootModule })
export class ApplicationsApiService extends SwaggerApiService {
    public constructor(http: HttpClient, @Optional() @Inject(BASE_URL) baseUrl: string) {
        super(http, baseUrl);
    }

    public PostSupport_byFormData<T extends BooleanApiSuccessResponseModel>(
        formData: Applications_PostSupport_FormData,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const response = this.http.request<T>('post', `${this.baseUrl}/api/Applications/Support`, {
            headers: requestHeaders,
            responseType: 'json',
            body: formData.ToFormData(),
        });

        return response.pipe(
            eitherify(),
            mapRight((response) => new BooleanApiSuccessResponseModel(response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public PostSales_byFormData<T extends BooleanApiSuccessResponseModel>(
        formData: Applications_PostSales_FormData,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const response = this.http.request<T>('post', `${this.baseUrl}/api/Applications/Sales`, {
            headers: requestHeaders,
            responseType: 'json',
            body: formData.ToFormData(),
        });

        return response.pipe(
            eitherify(),
            mapRight((response) => new BooleanApiSuccessResponseModel(response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public PostCommon_byFormData<T extends BooleanApiSuccessResponseModel>(
        formData: Applications_PostCommon_FormData,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const response = this.http.request<T>('post', `${this.baseUrl}/api/Applications/Common`, {
            headers: requestHeaders,
            responseType: 'json',
            body: formData.ToFormData(),
        });

        return response.pipe(
            eitherify(),
            mapRight((response) => new BooleanApiSuccessResponseModel(response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }
}
