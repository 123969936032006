<mat-card>
    <mat-card-content>
        <div>
            <p class="text-header-5 font-header-5 mb-8 break-words">
                {{ item.Title }}
            </p>
            <p
                class="mb-14 font-label-2 text-label-2 text-secondary-light break-words"
            >
                {{ item.Content }}
            </p>
        </div>
        <div class="flex gap-x-18 items-center">
            <shared-ui-rating-card
                [likeCount]="item.LikeCount"
                [dislikeCount]="item.DislikeCount"
                [liked]="item.Liked"
                [disliked]="item.Disliked"
                (likeClick)="likeBtnClicked()"
                (dislikeClick)="dislikeBtnClicked()"
            ></shared-ui-rating-card>
            <div class="status">
                <shared-ui-status-info
                    [text]="item.Status"
                    [color]="'transparentSecondary'"
                ></shared-ui-status-info>
            </div>
        </div>
    </mat-card-content>
</mat-card>
