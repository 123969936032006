import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';

@Component({
    selector: 'shared-ui-content-layout',
    templateUrl: './content-layout.component.html',
    styleUrls: ['./content-layout.component.scss'],
})
export class ContentLayoutComponent extends BaseComponent implements OnInit {
    @Input()
    @HostBinding('style.--content-auto-rows')
    autoRows?: string;

    

    ngOnInit(): void {}
}
