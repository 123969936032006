<div
    class="
        document-icon
        w-26
        h-36
        bg-white
        shadow-level-1
        flex flex-col flex-none
        items-start
        px-2
        py-[3px]
        gap-[1px]
    "
>
    <div class="w-14 h-2 bg-neutral-main"></div>
    <div class="w-18 h-2 bg-neutral-main mb-[1px]"></div>
    <div class="w-full h-[1px] bg-neutral-main"></div>
    <div class="w-full h-[1px] bg-neutral-main"></div>
    <div class="w-full h-[1px] bg-neutral-main"></div>
    <div class="w-14 h-[1px] bg-neutral-main mb-[1px]"></div>
    <div class="w-full h-[1px] bg-neutral-main"></div>
    <div class="w-full h-[1px] bg-neutral-main"></div>
    <div class="w-14 h-[1px] bg-neutral-main mb-[1px]"></div>
    <div class="flex w-full">
        <div class="flex flex-col flex-none gap-[1px] w-[50%]">
            <div class="w-full h-[1px] bg-neutral-main"></div>
            <div class="w-full h-[1px] bg-neutral-main"></div>
            <div class="w-full h-[1px] bg-neutral-main"></div>
            <div class="w-6 h-[1px] bg-neutral-main"></div>
        </div>
        <div
            class="
                flex-none
                text-[7px]
                leading-[7px]
                text-neutral-main
                w-[50%]
                text-center
                font-bold font-styrene
                uppercase
            "
        >
            {{ extensionFirstLetter }}
        </div>
    </div>
</div>
