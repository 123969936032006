<div class="empty-feature-info-container flex flex-wrap max-w-[660px] gap-20">
    <ic-icon
        icon="docs-with-cogs"
        [height]="'165px'"
        [width]="'150px'"
    ></ic-icon>
    <div class="flex flex-col flex-1">
        <p class="text-paragraph-2 font-paragraph-2 text-secondary-light mb-8">
            В данный момент, нет предложенного функционала для голосования. Вы
            можете описать свои пожелания нажав на кнопку “Предложить
            функционал”
        </p>
        <p class="text-secondary-extra-light text-label-2 font-label-2 mb-14">
            После предложения функционала, Ваше предложение будет отображаться
            здесь для дальнейшего голосования среди зарегистрированных
            пользователей
        </p>
        <shared-ui-button (click)="addFeature.emit()"
            >ПРЕДЛОЖИТЬ ФУНКЦИОНАЛ</shared-ui-button
        >
    </div>
</div>
