import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RibbonComponent } from './ribbon/ribbon.component';
import { RibbonItemComponent } from './ribbon-item/ribbon-item.component';

@NgModule({
    declarations: [RibbonComponent, RibbonItemComponent],
    imports: [CommonModule],
    exports: [RibbonComponent, RibbonItemComponent],
})
export class RibbonModule {}
