import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';

@Component({
    selector: 'shared-ui-ribbon',
    templateUrl: './ribbon.component.html',
    styleUrls: ['./ribbon.component.scss'],
})
export class RibbonComponent extends BaseComponent implements OnInit {
    ngOnInit(): void {}
}
