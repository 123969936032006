import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import { AppColor, AppColorVariants } from '@idocs/tailwind';

@Component({
    selector: 'shared-ui-ribbon-item',
    templateUrl: './ribbon-item.component.html',
    styleUrls: ['./ribbon-item.component.scss'],
})
export class RibbonItemComponent extends BaseComponent implements OnInit {
    @Input() background: `bg-${AppColor}-${AppColorVariants}` =
        'bg-neutral-lighter';
    @Input() border: `border-l-${AppColor}-${AppColorVariants}` =
        'border-l-neutral-lighter';
    zIndex = 1000;
    layer = `z-[${this.zIndex}]`;
    stretch = '';

    @Input()
    set index(number: number) {
        this.zIndex = this.zIndex - number * 100;
        this.layer = `z-[${this.zIndex}]`;
        if (this.zIndex < 1000) {
            this.layer = this.layer + ' -ml-20';
        }
    }

    @Input()
    set isStretchable(bool: boolean) {
        this.stretch = '';
        if (bool) {
            this.stretch = 'flex-1';
        }
    }

    ngOnInit(): void {}
}
