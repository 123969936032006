import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import { IdentityConnectorClient } from '@idocskz/identity-connector';
import { SharedAccountModel } from '@idocs/shared-logic';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ToolbarConfig } from '@idocs/shared-ui/drawer-layout/toolbar/toolbar.component';
import { CompanyInfo, PersonInfo } from '@idocs/shared-ui/account-card';
import { IconifyIcon } from '@idocs/icons';
import mdiDomain from '@iconify/icons-mdi/domain';
import { CertificateIssuerType } from '@idocs/api/identity/models/CertificateIssuerType.model';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'doc-documents-app-layout',
    templateUrl: './documents-app-layout.component.html',
    styleUrls: ['./documents-app-layout.component.scss'],
})
export class DocumentsAppLayoutComponent
    extends BaseComponent
    implements OnInit
{
    constructor(
        injector: Injector,
        private client: IdentityConnectorClient<SharedAccountModel>,
        private breakpointObserver: BreakpointObserver,
        private authService: AuthService
    ) {
        super(injector);
    }

    sidenavClosed = true;

    toolbarConfig = new ToolbarConfig({
        productTitle: 'Документы',
    });

    /**
     * Holds information about individual to show up in the toolbar of a page
     */
    personInfo?: PersonInfo;

    /**
     * Holds information about company or entrepreneur to show up in the toolbar of a page
     */
    companyInfo?: CompanyInfo;

    companyIcon: IconifyIcon = mdiDomain;

    ngOnInit(): void {
        this.fetchUserInfo();
    }

    private fetchUserInfo() {
        console.log('fetch');
        console.log(this.client.selectedAccount);
        if (this.client.selectedAccount?.tokenResult) {
            const token = this.client.selectedAccount.tokenResult;
            this.personInfo = new PersonInfo({
                lastName: token.LastName,
                firstName: token.FirstName,
                givenName: token.GivenName,
                imageInBase64: `${environment.apiServerUrl}/api/v2/photo/profile/${token.AccountId}`,
            });
            if (
                token.IssuerType == CertificateIssuerType.ENTERPRENEUR ||
                token.IssuerType == CertificateIssuerType.ORGANIZATION
            ) {
                this.companyInfo = new CompanyInfo({
                    name: token.CompanyName,
                    imageInBase64: `${environment.apiServerUrl}/api/v2/photo/company-profile/${token.CompanyId}`,
                });
            }
        }
    }

    switchAccount() {
        this.authService.switchAccount();
    }
}
