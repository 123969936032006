import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PackageFileSelectorVm } from '@idocs/shared-ui/document-preview';
import { BaseComponent } from '@idocs/shared-ui/core';

@Component({
    selector: 'shared-ui-package-file-selector',
    templateUrl: './package-file-selector.component.html',
    styleUrls: ['./package-file-selector.component.scss'],
})
export class PackageFileSelectorComponent
    extends BaseComponent
    implements OnInit
{
    @Input()
    data: PackageFileSelectorVm = new PackageFileSelectorVm();

    @Input()
    active?: boolean | null = false;

    @Output()
    selected = new EventEmitter();


    get selectable() {
        return this.selected.observers.length;
    }

    ngOnInit(): void {}
}
