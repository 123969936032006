<shared-ui-ribbon>
    <shared-ui-ribbon-item
        *ngFor="let ribbon of ribbonState.ribbons; let index = index"
        [background]="ribbon.background"
        [border]="ribbon.border"
        [index]="index"
        [isStretchable]="ribbon.isStretching"
    >
        <div
            class="flex flex-1 items-center pr-8"
            [class.text-neutral-extra-extra-lighter]="ribbon.declineText"
        >
            <div *ngIf="ribbon.mainIcon" class="text-display-1 mr-22">
                <ic-icon [icon]="ribbon.mainIcon"></ic-icon>
            </div>
            <div *ngIf="ribbon.usePersonInfo" class="flex flex-1 gap-16">
                <div class="flex flex-col gap-4 min-w-200">
                    <span class="text-paragraph-2">{{
                        ribbon.statusInfoText
                    }}</span>
                    <shared-ui-account-card
                        *ngIf="config.personInfo"
                        [personInfo]="config.personInfo"
                    ></shared-ui-account-card>
                </div>
                <div
                    *ngIf="ribbon.conditionalStatusText"
                    class="flex items-end max-w-200"
                >
                    <span class="text-label-3">{{
                        ribbon.conditionalStatusText
                    }}</span>
                </div>
                <div
                    *ngIf="ribbon.declineText"
                    class="flex flex-col ml-16 gap-4"
                >
                    <span class="text-paragraph-2 font-bold">
                        Причина отклонения
                    </span>
                    <span class="text-paragraph-2">
                        {{ ribbon.declineText }}
                    </span>
                </div>
            </div>
            <div
                *ngIf="ribbon.infoTooltipText"
                class="text-paragraph-1 text-neutral-main"
                [matTooltip]="ribbon.infoTooltipText"
            >
                <ic-icon [icon]="icon"></ic-icon>
            </div>
            <div
                *ngIf="ribbon.actions && ribbon.actions.length > 0"
                class="flex pl-30 pr-18 gap-20 justify-end"
            >
                <shared-ui-button
                    [disabled]="action.disabled || !!disabled"
                    [tooltip]="action.tooltip ?? ''"
                    *ngFor="let action of ribbon.actions"
                    [appearance]="action.theme"
                    (click)="click(action)"
                >
                    {{ action.title }}
                </shared-ui-button>
            </div>
            <div *ngIf="ribbon.statusTitle">
                <span
                    class="
                        text-header-5 text-neutral-extra-extra-lighter
                        uppercase
                        pr-28
                    "
                >
                    {{ ribbon.statusTitle }}
                </span>
            </div>
        </div>
    </shared-ui-ribbon-item>
</shared-ui-ribbon>
