import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import { FeedbackFormModel } from '@idocs/shared-ui/feedback-form';
import { FormControl } from '@angular/forms';
import { IFormControl } from 'ng-utils/extensions/form-control.extensions';
import { BLADE_DATA } from '@idocs/shared-ui/blade';

@Component({
    selector: 'lib-shared-feedback-form-blade',
    templateUrl: './shared-feedback-form-blade.component.html',
    styleUrls: ['./shared-feedback-form-blade.component.scss'],
})
export class SharedFeedbackFormBladeComponent
    extends BaseComponent
    implements OnInit
{
    constructor(
        injector: Injector,
        @Inject(BLADE_DATA)
        @Optional()
        private defaultValue: FeedbackFormModel | null
    ) {
        super(injector);
        console.log(defaultValue);
    }

    feedbackForm: IFormControl<FeedbackFormModel> = new FormControl(
        this.defaultValue ?? new FeedbackFormModel()
    ) as IFormControl<FeedbackFormModel>;

    isFormValid(): boolean {
        return this.feedbackForm.valid;
    }

    getResult(): FeedbackFormModel {
        return new FeedbackFormModel(this.feedbackForm.value);
    }

    ngOnInit(): void {}
}
