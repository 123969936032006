export enum AccountPermissions {
    EXTERNAL_OUTBOX_DOCUMENTS_EDIT = 1,
    EXTERNAL_INBOX_NEW_DOCUMENTS_EDIT = 2,
    EXTERNAL_INBOX_DOCUMENTS_EDIT = 4,
    EXTERNAL_ALL_DOCUMENTS_VIEW = 8,
    EXTERNAL_ALL_DOCUMENTS_APPROVE = 16,
    EXTERNAL_OUTBOX_DOCUMENTS_SIGN = 32,
    EXTERNAL_INBOX_DOCUMENTS_SIGN = 64,
    EXTERNAL_ALL_DOCUMENTS_DOWNLOAD = 128,
    INTERNAL_ALL_DOCUMENTS_EDIT = 256,
    INTERNAL_ALL_DOCUMENTS_DOWNLOAD = 512,
    IDOCS_V1_ACCOUNTING = 2048,
    IDOCS_V1_GENERAL = 4096,
}
