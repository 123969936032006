import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BaseComponent } from '@idocs/shared-ui/core';
import {
    FileUploaderComponent,
    FileUploaderConfig,
} from '@idocs/shared-ui/file-uploader';
import { FeatureInfoFormVm } from '../models';

@Component({
    selector: 'company-ui-feature-info-form',
    templateUrl: './feature-info-form.component.html',
    styleUrls: ['./feature-info-form.component.scss'],
})
export class FeatureInfoFormComponent extends BaseComponent implements OnInit {
    constructor(injector: Injector) {
        super(injector);
    }

    titleLength = 50;
    titleControl = new FormControl('', [
        Validators.required,
        Validators.maxLength(this.titleLength),
    ]);
    contentLength = 300;
    contentControl = new FormControl('', [
        Validators.required,
        Validators.maxLength(this.contentLength),
    ]);
    internalControl = new FormControl();
    externalControl = new FormControl();

    files: File[] = [];

    @ViewChild('fileUploader', { static: true })
    fileUploader?: FileUploaderComponent;

    fileUploaderConfig: FileUploaderConfig = new FileUploaderConfig({
        maxFilesAllowed: 5,
        maxFileSize: 3,
        acceptedFormat: '.jpg, .jpeg, .png, .pdf, .docx, .doc',
        title: '',
        uploadHint: 'Допустимые форматы: JPG, JPEG, PNG, PDF, WORD',
        description: `Загрузите или перетащите документ в это поле, чтобы начать загрузку файлов.`,
    });

    ngOnInit(): void {}

    filesRemoved(_: File[]) {
        this.files = this.fileUploader?.files ?? [];
    }

    filesAdded(_: File[]) {
        this.files = this.fileUploader?.files ?? [];
    }

    isFormValid(): boolean {
        return this.titleControl.valid && this.contentControl.valid;
    }

    getResult(): FeatureInfoFormVm {
        return new FeatureInfoFormVm({
            Title: this.titleControl.value ?? '',
            Content: this.contentControl.value ?? '',
            IsExternal: this.externalControl.value,
            IsInternal: this.internalControl.value,
            Files: this.files,
        });
    }
}
