import { NewFeatureRatingsCount } from './NewFeatureRatingsCount.model';
import { BaseModel, StrictBaseModelPart, Property, hasUnionKeys } from '@dev-stream/utils';

export class NewFeatureRatingsCountApiSuccessResponseModel extends BaseModel<NewFeatureRatingsCountApiSuccessResponseModel> {
    protected BASE_TYPE = NewFeatureRatingsCountApiSuccessResponseModel;
    'Header'!: string | null;
    'Description'!: string | null;
    @Property(() => NewFeatureRatingsCount)
    'Payload'!: NewFeatureRatingsCount | null;

    public GetDefaultValue(): StrictBaseModelPart<NewFeatureRatingsCountApiSuccessResponseModel> {
        return {
            Header: null,
            Description: null,
            Payload: null,
        };
    }

    public static isNewFeatureRatingsCountApiSuccessResponseModel(
        o: any
    ): o is NewFeatureRatingsCountApiSuccessResponseModel {
        return (
            o instanceof NewFeatureRatingsCountApiSuccessResponseModel ||
            hasUnionKeys(o, ['Header', 'Description', 'Payload'])
        );
    }
}
