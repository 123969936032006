import { Component, Inject, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import {
    InstructionsItemVm,
    InstructionsListVm,
} from '@idocs/shared-ui/instructions-list';
import { SharedInstructionsService } from '@idocs/shared-logic/shared-instructions/shared-instructions.service';
import { takeUntil, tap } from 'rxjs/operators';
import { mapRight } from '@dev-stream/utils';
import { BASE_URL } from '@idocs/api/corporate/services/CorporateServerApi.module';

@Component({
    selector: 'shared-ui-instructions-page',
    templateUrl: './instructions-page.component.html',
    styleUrls: ['./instructions-page.component.scss'],
})
export class InstructionsPageComponent extends BaseComponent implements OnInit {
    constructor(
        injector: Injector,
        private service: SharedInstructionsService,
        @Inject(BASE_URL) private siteApiUrl: string
    ) {
        super(injector);
    }

    instructions: InstructionsListVm[] = [];

    ngOnInit(): void {
        this.load();
    }

    load() {
        this.loading = true;
        this.service
            .get()
            .pipe(
                takeUntil(this.destroyed),
                tap(() => (this.loading = false)),
                mapRight((res) => this.service.mapToInstructionsVm(res)),
                mapRight((res) => (this.instructions = res))
            )
            .subscribe();
    }

    download(item: InstructionsItemVm) {
        const url = `${this.siteApiUrl}/api/Instructions/Download/${item.StorageId}/${item.FileName}`;
        window.open(url, '_blank');
    }
}
