import { Injectable } from '@angular/core';
import {
    BaseBladeConfig,
    BladeAction,
    BladeConfig,
    BladeService,
} from '@idocs/shared-ui/blade';
import { SharedFeedbackFormBladeComponent } from '../shared-feedback-form-blade/shared-feedback-form-blade.component';
import { FeedbackFormModel } from '@idocs/shared-ui/feedback-form';
import {
    BaseModelPart,
    IBaseModelConstructor,
    mapLeft,
} from '@dev-stream/utils';
import { Applications_PostCommon_FormData } from '@idocs/api/corporate/models/Applications_PostCommon_FormData.model';
import { Applications_PostSales_FormData } from '@idocs/api/corporate/models/Applications_PostSales_FormData.model';
import { Applications_PostSupport_FormData } from '@idocs/api/corporate/models/Applications_PostSupport_FormData.model';
import { ApplicationsApiService } from '@idocs/api/corporate/services/Applications.api-service';
import { map, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { apiResponseErrorHandler, Disposable } from '@idocs/shared-logic';
import { showNotification } from '@idocs/shared-ui/notification';

export type FeedbackType = 'support' | 'sales' | 'common';

export const TITLE_MAP = new Map<FeedbackType, string>()
    .set('common', 'Общий запрос')
    .set('support', 'Заявка в техподдержку')
    .set('sales', 'Заявка в отдел продаж');

export const REQUEST_MODEL_MAP = new Map<
    FeedbackType,
    IBaseModelConstructor<
        | Applications_PostCommon_FormData
        | Applications_PostSales_FormData
        | Applications_PostSupport_FormData
    >
>()
    .set('common', Applications_PostCommon_FormData)
    .set('support', Applications_PostSupport_FormData)
    .set('sales', Applications_PostSales_FormData);

@Injectable({ providedIn: 'root' })
export class SharedFeedbackService extends Disposable {
    constructor(
        private blade: BladeService,
        private feedbackApi: ApplicationsApiService
    ) {
        super();
    }

    private cancelAction = new BladeAction({
        color: 'accent',
        appearance: 'outline',
        text: 'Отменить',
    });

    private confirmAction = new BladeAction<
        FeedbackFormModel,
        SharedFeedbackFormBladeComponent
    >({
        color: 'accent',
        appearance: 'default',
        text: 'Отправить',
        result: (componentInstance) => componentInstance.getResult(),
        disabled: (componentInstance) => !componentInstance.isFormValid(),
    });

    openFeedbackForm(
        type: FeedbackType,
        defaultValue: FeedbackFormModel,
        cancellationObservable: Observable<any> = this.destroyed,
        config: BaseModelPart<BaseBladeConfig> = {}
    ) {
        const baseBladeConfig = new BaseBladeConfig(
            {
                side: 'right',
                title: TITLE_MAP.get(type),
            },
            config
        );

        const bladeConfig = new BladeConfig<
            SharedFeedbackFormBladeComponent,
            FeedbackFormModel,
            FeedbackFormModel | null | undefined
        >(baseBladeConfig, {
            componentOrTemplate: SharedFeedbackFormBladeComponent,
            actions: [this.cancelAction, this.confirmAction],
            beforeClose: (result) =>
                !!result
                    ? this.sendRequest(type, result, cancellationObservable)
                    : true,
            data: defaultValue,
        });

        return this.blade
            .open(bladeConfig)
            .onClose.pipe(takeUntil(cancellationObservable));
    }

    private sendRequest(
        type: FeedbackType,
        value: FeedbackFormModel,
        cancellationObservable: Observable<any>
    ) {
        console.log(value);
        const model = SharedFeedbackService.constructModel(
            REQUEST_MODEL_MAP.get(type)!,
            value
        );
        const request = this.getRequest(model);
        console.log(model);
        return request.pipe(
            takeUntil(cancellationObservable),
            mapLeft(apiResponseErrorHandler),
            mapLeft(showNotification),
            map((r) => r.isRight())
        );
    }

    private static constructModel<
        T extends IBaseModelConstructor<
            | Applications_PostCommon_FormData
            | Applications_PostSales_FormData
            | Applications_PostSupport_FormData
        >
    >(
        ctr: T,
        value: FeedbackFormModel
    ):
        | Applications_PostCommon_FormData
        | Applications_PostSales_FormData
        | Applications_PostSupport_FormData {
        return new ctr({
            'Data.CompanyName': value.companyName,
            'Data.Email': value.email,
            'Data.FullName': value.name,
            Files: value.files,
            'Data.Message': value.description,
            'Data.PhoneNumber': value.phone,
        });
    }

    private getRequest(
        model:
            | Applications_PostCommon_FormData
            | Applications_PostSales_FormData
            | Applications_PostSupport_FormData
    ) {
        if (model instanceof Applications_PostCommon_FormData) {
            return this.feedbackApi.PostCommon_byFormData(model);
        } else if (model instanceof Applications_PostSales_FormData) {
            return this.feedbackApi.PostSales_byFormData(model);
        } else {
            return this.feedbackApi.PostSupport_byFormData(model);
        }
    }
}
