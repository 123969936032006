import { ApplicationEnvironment } from './environment.model';
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.

export const environment = new ApplicationEnvironment({
    apiServerUrl: 'https://api.refactor.idocs.kz',
    identityServerUrl: 'https://identity.refactor.idocs.kz',
    // identityServerUrl: 'http://localhost:5001',
    corporateServerUrl: 'https://site.api.refactor.idocs.kz',
    authenticationHost: 'https://account.refactor.idocs.kz',
    // authenticationHost: 'https://localhost:4201',
    documentHost: 'https://cabinet.refactor.idocs.kz',
    isProductionMode: false,
});
