import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import { IconifyIcon } from '@idocs/icons';
import mdiHelpCircleOutline from '@iconify/icons-mdi/help-circle-outline';
import icRoundEditLocation from '@iconify/icons-ic/round-edit-location';
import mdiCode from '@iconify/icons-mdi/code';
import mdiStar from '@iconify/icons-mdi/star';
import mdiDownload from '@iconify/icons-mdi/download';

@Component({
    selector: 'shared-ui-help-pages-navigation',
    templateUrl: './help-pages-navigation.component.html',
    styleUrls: ['./help-pages-navigation.component.scss'],
})
export class HelpPagesNavigationComponent
    extends BaseComponent
    implements OnInit
{
    helpIcon: IconifyIcon = mdiHelpCircleOutline;
    contactsIcon: IconifyIcon = icRoundEditLocation;
    faqIcon: IconifyIcon = mdiHelpCircleOutline;
    instructionsIcon: IconifyIcon = mdiCode;
    featuresIcon: IconifyIcon = mdiStar;
    downloadsIcon: IconifyIcon = mdiDownload;

    ngOnInit(): void {}
}
