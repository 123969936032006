import { BaseModel, StrictBaseModelPart } from '@dev-stream/utils';

export class FeedbackFormModel extends BaseModel<FeedbackFormModel> {
    protected BASE_TYPE = FeedbackFormModel;

    name!: string;
    phone!: string;
    email!: string;
    companyName!: string;
    description!: string;
    files!: Blob[];

    GetDefaultValue(): StrictBaseModelPart<FeedbackFormModel, never> {
        return {
            companyName: '',
            name: '',
            description: '',
            email: '',
            files: [],
            phone: '',
        };
    }
}
