import { BaseModel, StrictBaseModelPart, hasUnionKeys } from '@dev-stream/utils';

export class ApplicationNewFeatureDTO extends BaseModel<ApplicationNewFeatureDTO> {
    protected BASE_TYPE = ApplicationNewFeatureDTO;
    'Phone'!: string | null;
    'Email'!: string | null;
    'Title'!: string | null;
    'Description'!: string | null;
    'IsInternal'!: boolean | null;
    'IsExternal'!: boolean | null;

    public GetDefaultValue(): StrictBaseModelPart<ApplicationNewFeatureDTO> {
        return {
            Phone: null,
            Email: null,
            Title: null,
            Description: null,
            IsInternal: null,
            IsExternal: null,
        };
    }

    public static isApplicationNewFeatureDTO(o: any): o is ApplicationNewFeatureDTO {
        return (
            o instanceof ApplicationNewFeatureDTO ||
            hasUnionKeys(o, ['Phone', 'Email', 'Title', 'Description', 'IsInternal', 'IsExternal'])
        );
    }
}
