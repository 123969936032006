import { BaseModel, StrictBaseModelPart, hasUnionKeys } from '@dev-stream/utils';

export class BooleanApiSuccessResponseModel extends BaseModel<BooleanApiSuccessResponseModel> {
    protected BASE_TYPE = BooleanApiSuccessResponseModel;
    'Header'!: string | null;
    'Description'!: string | null;
    'Payload'!: boolean | null;

    public GetDefaultValue(): StrictBaseModelPart<BooleanApiSuccessResponseModel> {
        return {
            Header: null,
            Description: null,
            Payload: null,
        };
    }

    public static isBooleanApiSuccessResponseModel(o: any): o is BooleanApiSuccessResponseModel {
        return o instanceof BooleanApiSuccessResponseModel || hasUnionKeys(o, ['Header', 'Description', 'Payload']);
    }
}
