import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppIconsModule } from '@idocs/shared-ui/app-icons';
import { ImageComponent } from './image/image.component';

@NgModule({
    declarations: [ImageComponent],
    imports: [CommonModule, AppIconsModule],
    exports: [ImageComponent],
})
export class ImageModule {}
