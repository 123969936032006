<p class="text-paragraph-2 font-paragraph-2 text-secondary-light mb-16">
    Чтобы оставить предложение, пожалуйста, заполните все поля
    ниже. После рассмотрения мы его разместим анонимно
    для голосования среди зарегистрированных пользователей.
</p>
<shared-ui-input>
    <span sharedUiInputLabel>Предположительное название</span>
    <input
        type="text"
        [maxLength]="titleLength"
        matInput
        sharedUiInput
        [formControl]="titleControl"
    />
</shared-ui-input>

<p
    class="
        mt-24
        mb-10
        font-label-2 font-medium
        text-label-2 text-primary-main
        uppercase
    "
>
    Вид документооборота
</p>
<mat-checkbox [formControl]="internalControl" sharedUiInput matInput
    ><p class="text-paragraph-2 font-paragraph-2">
        Внутренний документооборот
    </p></mat-checkbox
>
<mat-checkbox [formControl]="externalControl" sharedUiInput matInput
    ><p class="text-paragraph-2 font-paragraph-2">
        Внешний документооборот
    </p></mat-checkbox
>

<shared-ui-input class="mt-16">
    <span sharedUiInputLabel>Описание</span>
    <textarea
        type="text"
        matInput
        sharedUiInput
        cdkTextareaAutosize
        [formControl]="contentControl"
        [maxLength]="contentLength"
        rows="5"
    ></textarea>
</shared-ui-input>

<p class="text-header-6 font-header-6 mt-24">Выбор файла</p>
<p class="text-paragraph-2 font-paragraph-2 text-secondary-light my-12">
    Вы можете прикрепить до {{ fileUploaderConfig.maxFilesAllowed }} файлов.
    Максимальный размер файла {{ fileUploaderConfig.maxFileSize }}Мб.
</p>
<shared-ui-file-uploader
    #fileUploader
    [config]="fileUploaderConfig"
    (filesAdded)="filesAdded($event)"
    (fileRemoved)="filesRemoved($event)"
></shared-ui-file-uploader>
