import { Component, Inject, Injector, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@idocs/shared-ui/core';
import { eitherify, mapLeft, mapRight } from '@dev-stream/utils';
import { apiBlobResponseErrorHandler } from '@idocs/shared-logic';
import { showNotification } from '@idocs/shared-ui/notification';
import { BASE_URL } from '@idocs/api/business/services/BusinessServerApi.module';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { getAttachmentFileName } from 'ng-utils/utils';

@Component({
    selector: 'shared-ui-downloads-page',
    templateUrl: './downloads-page.component.html',
    styleUrls: ['./downloads-page.component.scss'],
})
export class DownloadsPageComponent extends BaseComponent implements OnInit {
    constructor(
        injector: Injector,
        @Inject(BASE_URL) private businessApiUrl: string,
        private httpClient: HttpClient
    ) {
        super(injector);
    }

    ngOnInit(): void {}

    download() {
        this.httpClient
            .get(
                `${this.businessApiUrl}/api/v2/integration-modules/download/1c`,
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
            .pipe(
                takeUntil(this.destroyed),
                eitherify(),
                mapLeft(async (e) => {
                    let res = await apiBlobResponseErrorHandler(e);
                    showNotification(res);
                }),
                mapRight((response) => {
                    if (!response.ok || !response.body) {
                        return;
                    }
                    const file = response.body;
                    const disposition = response.headers.get(
                        'Content-Disposition'
                    );

                    let filename =
                        getAttachmentFileName(disposition) ?? 'module.epf';

                    saveAs(file, filename);
                })
            )
            .subscribe();
    }

    downloadZup() {
        this.httpClient
            .get(
                `${this.businessApiUrl}/api/v2/integration-modules/download/zup`,
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
            .pipe(
                takeUntil(this.destroyed),
                eitherify(),
                mapLeft(async (e) => {
                    let res = await apiBlobResponseErrorHandler(e);
                    showNotification(res);
                }),

                mapRight((response) => {
                    if (!response.ok || !response.body) {
                        return;
                    }
                    const file = response.body;
                    const disposition = response.headers.get(
                        'Content-Disposition'
                    );

                    let filename =
                        decodeURI(getAttachmentFileName(disposition)) ??
                        'module.epf';

                    saveAs(file, filename);
                })
            )
            .subscribe();
    }
}
