<div class="flex flex-col gap-20">
    <p>
        Заполните форму. Специалисты свяжутся с вами в ближайшее рабочее время.
    </p>
    <shared-ui-input>
        <span sharedUiInputLabel>ФИО</span>
        <input type="text" sharedUiInput matInput [formControl]="nameControl" />
    </shared-ui-input>

    <shared-ui-input>
        <span sharedUiInputLabel>Телефон</span>
        <input
            type="text"
            sharedUiInput
            matInput
            prefix="+7 "
            mask="000 000-00-00"
            [showMaskTyped]="true"
            [validation]="true"
            [formControl]="phoneControl"
        />
    </shared-ui-input>

    <shared-ui-input>
        <span sharedUiInputLabel>Email</span>
        <input
            type="text"
            sharedUiInput
            matInput
            [formControl]="emailControl"
        />
    </shared-ui-input>

    <shared-ui-input>
        <span sharedUiInputLabel>Название компании</span>
        <input
            type="text"
            sharedUiInput
            matInput
            [formControl]="companyNameControl"
        />
    </shared-ui-input>

    <shared-ui-input>
        <span sharedUiInputLabel>Заявка</span>
        <textarea
            rows="3"
            sharedUiInput
            matInput
            cdkTextareaAutosize
            [formControl]="descriptionControl"
        ></textarea>
    </shared-ui-input>
    <h4 class="font-header-6 text-16">Выбор файла</h4>
    <p class="text-16 font-paragraph-2 text-secondary-extra-light">
        Вы можете прикрепить до 5 файлов. Максимальный размер файла 2Мб.
    </p>
    <shared-ui-file-uploader
        #fileUploader
        [config]="fileUploaderConfig"
        (filesAdded)="filesAdded($event)"
        (fileRemoved)="filesRemoved($event)"
    ></shared-ui-file-uploader>
</div>
