import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import { BreadcrumbsService } from '../../services/breadcrumbs.service';
import { takeUntil } from 'rxjs/operators';
import { PageVm } from '@idocs/shared-ui/breadcrumbs/models';
import { IconifyIcon } from '@idocs/icons';
import mdiChevronRight from '@iconify/icons-mdi/chevron-right';

@Component({
    selector: 'shared-ui-breadcrumbs-host',
    templateUrl: './breadcrumbs-host.component.html',
    styleUrls: ['./breadcrumbs-host.component.scss'],
    providers: [BreadcrumbsService],
})
export class BreadcrumbsHostComponent extends BaseComponent implements OnInit {
    constructor(injector: Injector, private service: BreadcrumbsService) {
        super(injector);
    }

    pages: PageVm[] = [];
    currentPage: PageVm = new PageVm();
    dividerIcon: IconifyIcon = mdiChevronRight;

    ngOnInit(): void {
        this.service.renderPages$
            .pipe(takeUntil(this.destroyed))
            .subscribe((pages) => {
                this.pages = pages.map(PageVm.mapFromPageComponent);
                this.currentPage = this.pages[this.pages.length - 1];
            });
    }
}
