import { Component, Injector, OnInit, Optional } from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import {
    FeedbackType,
    SharedFeedbackService,
} from '@idocs/shared-logic/shared-feedback';
import { notNull } from '@dev-stream/utils';
import { FeedbackFormModel } from '@idocs/shared-ui/feedback-form';
import { filter, takeUntil } from 'rxjs/operators';
import {
    NotificationConfiguration,
    showNotification,
} from '@idocs/shared-ui/notification';
import {
    IdentityConnectorClient,
    IdentityConnectorServer,
} from '@idocskz/identity-connector';
import { SharedAccountModel } from '@idocs/shared-logic';

@Component({
    selector: 'shared-ui-contacts-page',
    templateUrl: './contacts-page.component.html',
    styleUrls: ['./contacts-page.component.scss'],
})
export class ContactsPageComponent extends BaseComponent implements OnInit {
    constructor(
        injector: Injector,
        private feedbackService: SharedFeedbackService,
        @Optional()
        private client: IdentityConnectorClient<SharedAccountModel> | null,
        @Optional()
        private server: IdentityConnectorServer<SharedAccountModel> | null
    ) {
        super(injector);
    }
    ngOnInit(): void {}

    private get selectedAccount() {
        return (
            this.client?.selectedAccount ??
            this.server?.selectedAccount ??
            new SharedAccountModel()
        );
    }

    openFeedbackForm(type: FeedbackType) {
        const account = this.selectedAccount;
        this.feedbackService
            .openFeedbackForm(
                type,
                new FeedbackFormModel({
                    name: `${account.tokenResult.LastName} ${account.tokenResult.FirstName} ${account.tokenResult.GivenName}`,
                    companyName: account.tokenResult.CompanyName ?? '',
                    phone: account.tokenResult.PhoneNumber ?? '',
                    email: account.tokenResult.Email ?? '',
                }),
                this.destroyed
            )
            .pipe(takeUntil(this.destroyed), filter(notNull))
            .subscribe(() =>
                showNotification(
                    new NotificationConfiguration({
                        color: 'success',
                        content: 'Заявка отправлена',
                    })
                )
            );
    }
}
