<ng-content select="shared-ui-toolbar"></ng-content>
<div class="flex flex-col flex-1 items-stretch {{ classes }}">
    <mat-sidenav-container>
        <mat-sidenav [mode]="mode" #sidenav [opened]="sidenavOpened">
            <div class="flex h-full flex-col items-stretch flex-none">
                <ng-content select="[drawerLayoutSidenav]"></ng-content>
            </div>
        </mat-sidenav>
        <mat-sidenav-content
            class="
                scroll scroll-track-neutral-extra-extra-lighter scroll-w-10
                px-8
                gt-sm:px-16
            "
        >
            <div
                class="
                    content-grid-wrapper
                    min-h-0
                    flex
                    h-full
                    flex-col
                    items-stretch
                    flex-1
                "
                [class.sidenav-closed]="!sidenav.opened"
            >
                <ng-content select="[drawerLayoutContent]"></ng-content>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
