<div
    class="
        initials-image
        font-bold
        grid
        flex-1
        place-items-center
        w-full
        h-full
        shadow-level-1
        border-2 border-white
        pointer-events-auto
    "
    [ngClass]="
        extendByClasses(colorClasses, {
            'shimmer shimmer-accent-neutral-lighter': isLoading | async
        })
    "
>
    {{ text }}
</div>
