import { SwaggerApiService } from './SwaggerApiService.service';
import { SwaggerApiRequestHeaders } from './SwaggerApiService.service';
import { eitherify, mapLeft, mapRight } from '@dev-stream/utils';
import { Inject, Optional, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BASE_URL, CorporateServerApiRootModule } from './CorporateServerApi.module';
import { ApplicationNewFeatureViewModel } from '../models/ApplicationNewFeatureViewModel.model';
import { NewFeatureApplication_Add_FormData } from '../models/NewFeatureApplication_Add_FormData.model';
import { ApplicationNewFeatureDTOApplicationWithAttachmentsViewModelApiSuccessResponseModel } from '../models/ApplicationNewFeatureDTOApplicationWithAttachmentsViewModelApiSuccessResponseModel.model';
import { NewFeatureRatingsCountApiSuccessResponseModel } from '../models/NewFeatureRatingsCountApiSuccessResponseModel.model';

@Injectable({ providedIn: CorporateServerApiRootModule })
export class NewFeatureApplicationApiService extends SwaggerApiService {
    public constructor(http: HttpClient, @Optional() @Inject(BASE_URL) baseUrl: string) {
        super(http, baseUrl);
    }

    public Get<T extends Array<ApplicationNewFeatureViewModel>>(requestHeaders?: SwaggerApiRequestHeaders) {
        const response = this.http.request<T>(
            'get',
            `${this.baseUrl}/api/NewFeatureApplication/NewFeatureClientApplications`,
            {
                headers: requestHeaders,
                responseType: 'json',
            }
        );

        return response.pipe(
            eitherify(),
            mapRight((response) =>
                Array.isArray(response) ? response.map((i) => new ApplicationNewFeatureViewModel(i)) : response
            ),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public Add_byFormData<T extends ApplicationNewFeatureDTOApplicationWithAttachmentsViewModelApiSuccessResponseModel>(
        formData: NewFeatureApplication_Add_FormData,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const response = this.http.request<T>(
            'post',
            `${this.baseUrl}/api/NewFeatureApplication/AddNewFeatureClientApplication`,
            {
                headers: requestHeaders,
                responseType: 'json',
                body: formData.ToFormData(),
            }
        );

        return response.pipe(
            eitherify(),
            mapRight(
                (response) =>
                    new ApplicationNewFeatureDTOApplicationWithAttachmentsViewModelApiSuccessResponseModel(response)
            ),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public Like_byApplicationId<T extends NewFeatureRatingsCountApiSuccessResponseModel>(
        queryParams: ILike_byApplicationId_Params,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const params = this.$MapParams(queryParams);
        const response = this.http.request<T>(
            'post',
            `${this.baseUrl}/api/NewFeatureApplication/NewFeatureClientApplications-like`,
            {
                headers: requestHeaders,
                params,
                responseType: 'json',
            }
        );

        return response.pipe(
            eitherify(),
            mapRight((response) => new NewFeatureRatingsCountApiSuccessResponseModel(response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public DisLike_byApplicationId<T extends NewFeatureRatingsCountApiSuccessResponseModel>(
        queryParams: IDisLike_byApplicationId_Params,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const params = this.$MapParams(queryParams);
        const response = this.http.request<T>(
            'post',
            `${this.baseUrl}/api/NewFeatureApplication/NewFeatureClientApplications-Dislike`,
            {
                headers: requestHeaders,
                params,
                responseType: 'json',
            }
        );

        return response.pipe(
            eitherify(),
            mapRight((response) => new NewFeatureRatingsCountApiSuccessResponseModel(response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }
}

interface ILike_byApplicationId_Params {
    applicationId?: string | null;
}

interface IDisLike_byApplicationId_Params {
    applicationId?: string | null;
}
