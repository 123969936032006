<div
    class="flex flex-col items-stretch"
    [class.shadow-level-2]="hasContent && isShaded"
    [ngClass]="classes"
>
    <div class="flex items-start select-none">
        <div
            class="
                flex flex-1
                items-baseline
                justify-between
                gap-6
                p-2
                mt-[1px]
                rounded-4
                bg-white
            "
            [class.shadow-level-2]="!hasContent && isShaded"
            matRipple
            [class.shimmer]="isLoading | async"
            [matRippleDisabled]="!hasSubscribers || !!disabled"
            [class.-mr-18]="personInfo && companyInfo"
            [class.pr-24]="personInfo && companyInfo"
            [class.cursor-pointer]="hasSubscribers && !disabled"
            [class.cursor-not-allowed]="!!disabled"
            (click)="!disabled && selected.emit()"
        >
            <div
                *ngIf="personInfo"
                class="person-info-image flex-none w-24 h-24 m-[3px]"
            >
                <shared-ui-initials-image
                    *ngIf="
                        !personInfo.imageInBase64 || imageFailedToLoad;
                        else personInfoImageTemplate
                    "
                    [disabled]="disabled"
                    [loading]="loading"
                    class="rounded-full text-[10px]"
                    text="{{ personInfo.lastName?.[0] ?? '' }}{{ personInfo.firstName?.[0] ?? ''}}"
                ></shared-ui-initials-image>
                <ng-template #personInfoImageTemplate>
                    <shared-ui-image
                        (blobFailedToLoad)="imageFailedToLoad = $event"
                        [config]="personImageConfig"
                    ></shared-ui-image>
                </ng-template>
            </div>
            <div
                *ngIf="companyInfo && !personInfo"
                class="
                    company-info-image
                    flex-none flex
                    items-center
                    justify-center
                    w-26
                    h-26
                    mr-6
                    rounded-4
                    shadow-level-2
                    bg-neutral-extra-lighter
                    border-2 border-solid border-white
                "
            >
                <div class="text-[20px] text-neutral-light">
                    <shared-ui-image
                        [rounded]="false"
                        [config]="companyImageConfig"
                        [iconSize]="20"
                        [plugSrc]="companyIcon"
                    ></shared-ui-image>
                </div>
            </div>

            <div
                class="
                    person-info
                    self-stretch
                    flex flex-col flex-1
                    items-stretch
                    justify-center
                    min-w-min
                    px-6
                "
            >
                <span class="text-on-light-base text-label-2 font-label-2">
                    <span [class.text-neutral-main]="!!disabled">
                        <span
                            class="
                                whitespace-nowrap
                                overflow-hidden overflow-ellipsis
                            "
                            *ngIf="personInfo"
                        >
                            {{ personInfo.lastName }}
                            {{ personInfo.firstNameInitial }}
                            {{ personInfo.givenNameInitial }}
                        </span>
                        <ng-container *ngIf="companyInfo && !personInfo">
                            {{ companyInfo.name }}
                        </ng-container>
                    </span>
                </span>
                <span
                    class="text-on-light-disabled text-label-3 font-label-3"
                    *ngIf="personInfo?.position"
                    [matTooltip]="positionTooltip"
                >
                    <span [class.text-neutral-main]="!!disabled">
                        {{ personInfo?.position ?? '' | truncate: [138] }}
                    </span>
                </span>
            </div>

            <div
                *ngIf="companyInfo && personInfo"
                class="
                    pt-2
                    self-start
                    company-info
                    flex flex-col flex-1
                    text-right
                    justify-center
                    text-on-light-base text-label-3
                    h-30
                    items-end
                "
            >
                <span
                    [class.text-neutral-main]="!!disabled"
                    [matTooltip]="
                        showCompanyNameTooltip ? companyInfo.name ?? '' : ''
                    "
                    class="line-clamp-2"
                >
                    {{ companyInfo.name }}
                </span>
            </div>
            <ng-content select="shared-ui-account-card-status"></ng-content>
        </div>
        <div
            *ngIf="personInfo && companyInfo"
            class="
                flex flex-col flex-none
                z-10
                items-center
                justify-center
                shadow-level-2
                p-4
                w-36
                h-36
                bg-white
                rounded-4
            "
        >
            <div
                class="
                    flex-none flex
                    items-center
                    justify-center
                    w-32
                    h-32
                    rounded-4
                    shadow-level-2
                    bg-neutral-extra-lighter
                    border-2 border-solid border-white
                "
            >
                <div class="text-[16px] text-neutral-light">
                    <shared-ui-image
                        [rounded]="false"
                        [config]="companyImageConfig"
                        [iconSize]="16"
                        [plugSrc]="companyIcon"
                    ></shared-ui-image>
                </div>
            </div>
        </div>
    </div>
    <ng-content select="shared-ui-account-card-content"></ng-content>
</div>
