import { BaseModel, StrictBaseModelPart, hasUnionKeys } from '@dev-stream/utils';

export class InstructionViewModel extends BaseModel<InstructionViewModel> {
    protected BASE_TYPE = InstructionViewModel;
    'Id'!: string | null;
    'Name'!: string | null;
    'StorageId'!: string | null;
    'FileName'!: string | null;

    public GetDefaultValue(): StrictBaseModelPart<InstructionViewModel> {
        return {
            Id: null,
            Name: null,
            StorageId: null,
            FileName: null,
        };
    }

    public static isInstructionViewModel(o: any): o is InstructionViewModel {
        return o instanceof InstructionViewModel || hasUnionKeys(o, ['Id', 'Name', 'StorageId', 'FileName']);
    }
}
