import {
    AfterContentInit,
    Component,
    ContentChild,
    Injector,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import {
    ToolbarComponent,
    ToolbarConfig,
} from '@idocs/shared-ui/drawer-layout/toolbar/toolbar.component';
import { takeUntil } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AppBreakpoints } from '@idocs/shared-ui/models';

@Component({
    selector: 'shared-ui-drawer-layout',
    templateUrl: './drawer-layout.component.html',
    styleUrls: ['./drawer-layout.component.scss'],
})
export class DrawerLayoutComponent
    extends BaseComponent
    implements OnInit, AfterContentInit
{
    @ContentChild(ToolbarComponent)
    toolbar?: ToolbarComponent;

    @ViewChild(MatSidenav, { static: true })
    matSidenav?: MatSidenav;

    @Input()
    mode: 'over' | 'push' | 'side' = 'side';

    constructor(
        injector: Injector,
        private breakpointObserver: BreakpointObserver
    ) {
        super(injector);
    }

    @Input()
    sidenavOpened = true;

    @Input()
    toolbarConfig = new ToolbarConfig();

    ngOnInit(): void {
        this.breakpointObserver
            .observe(AppBreakpoints.LT_MD)
            .pipe(takeUntil(this.destroyed))
            .subscribe((bpState) => {
                this.mode = bpState.matches ? 'over' : 'side';
                if (!bpState.matches) {
                    this.matSidenav?.open();
                } else {
                    this.matSidenav?.close();
                }
            });
    }

    ngAfterContentInit() {
        if (this.toolbar) {
            this.toolbar.sidenavToggle
                .pipe(takeUntil(this.destroyed))
                .subscribe(() => {
                    this.matSidenav?.opened
                        ? this.matSidenav.close()
                        : this.matSidenav?.open();
                });
        }
    }
}
