import { Injectable } from '@angular/core';
import { mapLeft } from '@dev-stream/utils';
import { GetInstructionsViewModel } from '@idocs/api/corporate/models/GetInstructionsViewModel.model';
import { InstructionsApiService } from '@idocs/api/corporate/services/Instructions.api-service';
import { apiResponseErrorHandler, Disposable } from '@idocs/shared-logic';
import {
    InstructionsItemVm,
    InstructionsListVm,
} from '@idocs/shared-ui/instructions-list';
import { showNotification } from '@idocs/shared-ui/notification';
import { takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SharedInstructionsService extends Disposable {
    constructor(private instructionsApi: InstructionsApiService) {
        super();
    }

    get() {
        return this.instructionsApi
            .GetInstructions()
            .pipe(
                takeUntil(this.destroyed),
                mapLeft(apiResponseErrorHandler),
                mapLeft(showNotification)
            );
    }

    mapToInstructionsVm(items: GetInstructionsViewModel[]) {
        return items.map(
            (r) =>
                new InstructionsListVm({
                    Title: r.Category ?? '',
                    Items: r.Instructions.map(
                        (i) =>
                            new InstructionsItemVm({
                                Id: i?.Id ?? '',
                                Title: i?.Name ?? '',
                                FileName: i?.FileName ?? '',
                                StorageId: i?.StorageId ?? '',
                            })
                    ),
                })
        );
    }
}
