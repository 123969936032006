<shared-ui-drawer-layout
    class="px-8 gt-sm:px-16"
    [sidenavOpened]="!sidenavClosed"
>
    <shared-ui-toolbar [config]="toolbarConfig" [noDrawerButton]="true">
        <ng-container toolbarRight>
            <shared-ui-account-card
                class="w-[300px] max-w-full"
                [personInfo]="personInfo"
                [companyInfo]="companyInfo"
                (selected)="trigger.openMenu()"
                [showCompanyNameTooltip]="true"
            ></shared-ui-account-card>
            <div
                [matMenuTriggerFor]="accountMenu"
                #trigger="matMenuTrigger"
            ></div>
            <mat-menu #accountMenu xPosition="before">
                <button mat-menu-item (click)="switchAccount()">
                    <span>Сменить аккаунт</span>
                </button>
            </mat-menu>
        </ng-container>
    </shared-ui-toolbar>

    <ng-container drawerLayoutContent>
        <shared-ui-breadcrumbs-host>
            <shared-ui-page pageTitle="Документы" [isDisableNavigation]="true">
                <shared-ui-content-layout class="flex-1" [autoRows]="'1fr'">
                    <div
                        class="
                            page-content
                            min-h-0
                            flex-1 flex flex-col
                            items-stretch
                        "
                    >
                        <router-outlet></router-outlet>
                    </div>
                </shared-ui-content-layout>
            </shared-ui-page>
        </shared-ui-breadcrumbs-host>
    </ng-container>
</shared-ui-drawer-layout>
