import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';

@Component({
    selector: 'shared-ui-initials-image',
    templateUrl: './initials-image.component.html',
    styleUrls: ['./initials-image.component.scss'],
})
export class InitialsImageComponent extends BaseComponent implements OnInit {
    private _text: string = '';

    get text() {
        return this._text;
    }

    @Input()
    set text(v: string) {
        this._text = v.toUpperCase();
        this.calculateBackground();
    }

    background: string = InitialsImageComponent.colors[0][0];
    foreground: string = InitialsImageComponent.colors[0][1];

    get colorClasses() {
        return {
            [this.background]: !this.disabled,
            [this.foreground]: !this.disabled,
            ['bg-neutral-main text-neutral-extra-light']: this.disabled,
        };
    }

    private get score() {
        let s = 0;
        let m = 1;
        for (let i = 0; i < this.text.length; i++) {
            s += this.text.charCodeAt(i) * m;
            m *= 113;
        }
        return s % 12;
    }

    private calculateBackground() {
        const color = this.score;
        this.background = InitialsImageComponent.colors[color][0];
        this.foreground = InitialsImageComponent.colors[color][1];
    }

    ngOnInit(): void {}

    static colors = [
        ['bg-state-violet-main', 'text-on-dark-base'],
        ['bg-state-indigo-main', 'text-on-dark-base'],
        ['bg-state-blue-main', 'text-on-dark-base'],
        ['bg-state-cyan-main', 'text-on-dark-base'],
        ['bg-state-teal-main', 'text-on-dark-base'],
        ['bg-state-green-main', 'text-on-dark-base'],
        ['bg-state-lime-main', 'text-on-light-base'],
        ['bg-state-yellow-main', 'text-on-light-base'],
        ['bg-state-orange-main', 'text-on-light-base'],
        ['bg-state-red-main', 'text-on-dark-base'],
        ['bg-state-pink-main', 'text-on-dark-base'],
        ['bg-state-purple-main', 'text-on-dark-base'],
    ];
}
