import {
    BaseModel,
    EnumProperty,
    Property,
    StrictBaseModelPart,
} from '@dev-stream/utils';
import { PersonInfo } from '@idocs/shared-ui/account-card';
import { ButtonType } from '@idocs/shared-ui/button';
import { AppColor, AppColorVariants } from '@idocs/tailwind';

export class PackageFileSelectorVm extends BaseModel<PackageFileSelectorVm> {
    GetDefaultValue(): StrictBaseModelPart<PackageFileSelectorVm, never> {
        return {
            id: '',
            extension: null,
            name: '',
        };
    }
    protected BASE_TYPE = PackageFileSelectorVm;

    id!: string;
    name!: string;
    extension!: string | null;
}

export class DocumentThumbnailVm extends BaseModel<DocumentThumbnailVm> {
    protected BASE_TYPE = DocumentThumbnailVm;
    GetDefaultValue(): StrictBaseModelPart<DocumentThumbnailVm, never> {
        return {
            date: null,
            docCount: 0,
            title: '',
            isPackage: false,
            extension: null,
            id: '',
        };
    }

    id!: string;

    title!: string;

    @Property(() => Date)
    date!: Date | null;

    extension!: string | null;
    docCount!: number;
    isPackage!: boolean | null;
}

export class DocumentStatusRibbonConfig extends BaseModel<DocumentStatusRibbonConfig> {
    BASE_TYPE = DocumentStatusRibbonConfig;

    GetDefaultValue(): StrictBaseModelPart<DocumentStatusRibbonConfig, never> {
        return {
            state: DocumentPreviewStatusEnum.Default,
            isDynamicText: false,
        };
    }

    @Property(() => PersonInfo)
    personInfo?: PersonInfo;

    @EnumProperty(() => DocumentPreviewStatusEnum)
    state!: DocumentPreviewStatusEnum;

    declineReason?: string;
    conditionalStatusText?: string;
    statusInfoText?: string;

    actionsConfig?: DocumentStatusRibbonActionsConfig[];

    isDynamicText!: boolean;
}

export class DocumentStatusRibbonActionsConfig extends BaseModel<DocumentStatusRibbonActionsConfig> {
    BASE_TYPE = DocumentStatusRibbonActionsConfig;

    GetDefaultValue(): StrictBaseModelPart<
        DocumentStatusRibbonActionsConfig,
        never
    > {
        return {
            disabled: false,
            tooltip: '',
            actionEnum: DocumentStatusActionEnum.Sign,
        };
    }

    disabled!: boolean;
    tooltip!: string;
    actionEnum!: DocumentStatusActionEnum;
}

export enum DocumentPreviewStatusEnum {
    On_Approval,
    On_Familiarization,
    On_Signing,
    Approved,
    Familiarized,
    Signed,
    Declined,
    Default,
}

export class DocumentRibbonState extends BaseModel<DocumentRibbonState> {
    BASE_TYPE = DocumentRibbonState;

    GetDefaultValue(): StrictBaseModelPart<DocumentRibbonState, never> {
        return {
            ribbons: [],
            state: DocumentPreviewStatusEnum.Default,
        };
    }

    ribbons!: DocumentStatusRibbonVM[];
    state!: DocumentPreviewStatusEnum;
}

export class DocumentStatusRibbonVM extends BaseModel<DocumentStatusRibbonVM> {
    BASE_TYPE = DocumentStatusRibbonVM;

    GetDefaultValue(): StrictBaseModelPart<DocumentStatusRibbonVM, never> {
        return {
            usePersonInfo: true,
            background: 'bg-neutral-darker',
            border: 'border-l-neutral-darker',
            isStretching: false,
        };
    }

    mainIcon?: string;
    infoTooltipText?: string;
    actions?: DocumentStatusActionVM[];
    statusTitle?: string;
    statusInfoText?: string;
    conditionalStatusText?: string;
    usePersonInfo!: boolean;
    background!: `bg-${AppColor}-${AppColorVariants}`;
    border!: `border-l-${AppColor}-${AppColorVariants}`;
    isStretching!: boolean;
    declineText?: string;
}

export class DocumentStatusActionVM extends BaseModel<DocumentStatusActionVM> {
    BASE_TYPE = DocumentStatusActionVM;

    GetDefaultValue(): StrictBaseModelPart<DocumentStatusActionVM, never> {
        return {
            title: '',
            theme: 'default',
            actionState: DocumentStatusActionEnum.Sign,
            disabled: false,
        };
    }

    title!: string;
    theme!: ButtonType;
    actionState!: DocumentStatusActionEnum;
    disabled!: boolean;
    tooltip?: string;
}

export enum DocumentStatusActionEnum {
    Familiarize,
    Approve,
    Sign,
    Decline_Approval,
    Decline_Signing,
    All,
}
