<div
    class="package-file-selector py-6 px-12 flex gap-8 items-stretch border-l-4"
    [matTooltip]="data.name"
    [matTooltipPosition]="'after'"
    [ngClass]="
        extendByClasses({
            'border-l-transparent': !active || disabled,
            'hover:cursor-pointer hover:bg-neutral-extra-lighter':
                selectable && !disabled,
            'border-l-accent-main bg-neutral-extra-light':
                !!active && !disabled,
            'text-on-light-disabled cursor-not-allowed': disabled,
            shimmer: isLoading | async
        })
    "
    (click)="!disabled && selected.emit()"
>
    <shared-ui-document-icon
        [extension]="data.extension"
    ></shared-ui-document-icon>
    <div class="flex flex-col items-stretch">
        <span
            class="
                inline-flex
                leading-normal
                text-paragraph-3
                break-all
                whitespace-normal
                font-paragraph-3
                line-clamp-2
            "
            [class.font-bold]="active"
            >{{ data.name }}</span
        >
    </div>
</div>
