import { ApplicationNewFeatureDTO } from './ApplicationNewFeatureDTO.model';
import { BaseModel, StrictBaseModelPart, Property, hasUnionKeys } from '@dev-stream/utils';

export class ApplicationNewFeatureDTOApplicationWithAttachmentsViewModel extends BaseModel<ApplicationNewFeatureDTOApplicationWithAttachmentsViewModel> {
    protected BASE_TYPE = ApplicationNewFeatureDTOApplicationWithAttachmentsViewModel;
    @Property(() => ApplicationNewFeatureDTO)
    'Data'!: ApplicationNewFeatureDTO | null;
    'Files'!: Array<Blob | null>;

    public GetDefaultValue(): StrictBaseModelPart<ApplicationNewFeatureDTOApplicationWithAttachmentsViewModel> {
        return {
            Data: null,
            Files: [],
        };
    }

    public static isApplicationNewFeatureDTOApplicationWithAttachmentsViewModel(
        o: any
    ): o is ApplicationNewFeatureDTOApplicationWithAttachmentsViewModel {
        return (
            o instanceof ApplicationNewFeatureDTOApplicationWithAttachmentsViewModel || hasUnionKeys(o, ['Data', 'Files'])
        );
    }
}
