import { InstructionViewModel } from './InstructionViewModel.model';
import { BaseModel, StrictBaseModelPart, Property, hasUnionKeys } from '@dev-stream/utils';

export class GetInstructionsViewModel extends BaseModel<GetInstructionsViewModel> {
    protected BASE_TYPE = GetInstructionsViewModel;
    'Category'!: string | null;
    @Property(() => InstructionViewModel)
    'Instructions'!: Array<InstructionViewModel | null>;

    public GetDefaultValue(): StrictBaseModelPart<GetInstructionsViewModel> {
        return {
            Category: null,
            Instructions: [],
        };
    }

    public static isGetInstructionsViewModel(o: any): o is GetInstructionsViewModel {
        return o instanceof GetInstructionsViewModel || hasUnionKeys(o, ['Category', 'Instructions']);
    }
}
