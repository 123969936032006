import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import { InstructionsItemVm, InstructionsListVm } from '../models';

@Component({
    selector: 'shared-ui-instructions-list',
    templateUrl: './instructions-list.component.html',
    styleUrls: ['./instructions-list.component.scss'],
})
export class InstructionsListComponent extends BaseComponent implements OnInit {
    constructor(injector: Injector) {
        super(injector);
    }
    panelStateOpened = false;

    @Input() instruction: InstructionsListVm = new InstructionsListVm();
    @Output() clicked = new EventEmitter<InstructionsItemVm>();

    ngOnInit(): void {}

    itemClicked(item: InstructionsItemVm) {
        this.clicked.emit(item.Clone());
    }
}
