import { Injectable } from '@angular/core';
import { ITokenObtainer } from '@idocs/shared-logic';
import { HttpRequest } from '@angular/common/http';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class TokenObtainer implements ITokenObtainer {
    constructor(private authService: AuthService) {}

    obtainToken(): string {
        return `Bearer ${this.authService.tokenResult?.Token}`;
    }

    skipTokenInsertion(req: HttpRequest<any>): boolean {
        return (
            req.headers.get('skip-auth') == 'true' ||
            !this.authService.isAuthenticated()
        );
    }
}
