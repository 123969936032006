import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    Output,
    EventEmitter,
    Input,
    ViewChild,
} from '@angular/core';
import {
    DropzoneConfigInterface,
    DropzoneDirective,
} from 'ngx-dropzone-wrapper';
import trash from '@iconify/icons-mdi/trash';
import { BaseModel, StrictBaseModelPart } from '@dev-stream/utils';
import { IconifyIcon, uploadFileIcon } from '@idocs/icons';
import {
    NotificationConfiguration,
    showNotification,
} from '@idocs/shared-ui/notification';

export class FileUploaderConfig extends BaseModel<FileUploaderConfig> {
    GetDefaultValue(): StrictBaseModelPart<FileUploaderConfig, never> {
        return {
            multiple: true,
            title: 'Перетащите файл',
            description:
                'Перетащите файл в это поле или кликните на нем для выбора',
            icon: uploadFileIcon,
            maxFilesAllowed: 50,
            isCustomResultContent: false,
            isCustomDropAreaTemplate: false,
            isShowUploadResult: true,
        };
    }

    protected BASE_TYPE = FileUploaderConfig;

    multiple!: boolean;
    title!: string;
    description!: string;
    icon!: IconifyIcon;
    maxFilesAllowed!: number;
    acceptedFormat?: string;
    isCustomResultContent!: boolean;
    isCustomDropAreaTemplate!: boolean;
    isShowUploadResult!: boolean;
    maxFileSize?: number | null;
    uploadHint?: string | null;
}

@Component({
    selector: 'shared-ui-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit, AfterViewInit {
    @ViewChild(DropzoneDirective, { static: false })
    directiveRef?: DropzoneDirective;
    @Input() config = new FileUploaderConfig();
    @Input() disabled: boolean = false;
    /**
     * event that is emmited when file thumbnail is uploaded, use it if file preview is required (usual with images), because result files contain dataURL property
     */
    @Output() fileUploaded = new EventEmitter<any[]>();

    /**
     * event that is emmited when files are added, use it if file preview is not required, because files do not contain dataURL property at the time this event is emmited
     */
    @Output() filesAdded = new EventEmitter<any[]>();
    @Output() fileRemoved = new EventEmitter<any[]>();

    @Input()
    files: any[] = [];

    trash: IconifyIcon = trash;
    elRef: ElementRef;
    dragging = false;

    dropZoneConfig: DropzoneConfigInterface = {
        url: `test`,
        clickable: true,
        errorReset: null,
        cancelReset: null,
        autoReset: null,
        autoQueue: false,
        dictDefaultMessage: '',
        previewTemplate: '<div></div>',
    };

    constructor(el: ElementRef) {
        this.elRef = el;
    }

    ngOnInit(): void {
        this.dropZoneConfig = {
            maxFiles: this.config.multiple ? this.config.maxFilesAllowed : 1,
            uploadMultiple: this.config.multiple,
            acceptedFiles: this.config.acceptedFormat,
            maxFilesize: this.config.maxFileSize ?? 256, //256 MiB is default in dropzone
            ...this.dropZoneConfig,
            dictFileTooBig: `Размер загружаемого файла превышает ${this.config.maxFileSize} Мб. Выберите другой файл.`,
            dictMaxFilesExceeded: `Количество файлов превышает ${this.config.maxFilesAllowed}.`,
        };
    }

    ngAfterViewInit() {
        const elToRemove = this.elRef.nativeElement.querySelector(
            '.dz-default:not(.drop-content):not(.files-content)'
        ); //remove auto inserted element

        if (elToRemove) {
            elToRemove.remove();
        }
    }

    onUploadError($event: any) {
        if ($event && $event.length > 1 && $event[0].status == 'error') {
            showNotification(
                new NotificationConfiguration({
                    title: 'Предупреждение',
                    content: $event[1],
                    color: 'danger',
                })
            );
        }

        console.error('error: ', $event, this.config, this.dropZoneConfig);
    }

    addedFiles(files: any) {
        this.dragging = false;
        //reset dropzone for multiple files here because thumbnails (onUploadSuccess) are not always loaded correctly for large files
        this.resetDropzoneIfSingle();

        if (files?.length > 0) {
            let successFiles = [...files].filter((f) => {
                return f.status == 'added';
            });

            this.files.push(...successFiles);

            this.filesAdded.emit(successFiles);
        }
    }

    onUploadSuccess(result: any[]) {
        // result is array [file, dataURL]
        // all files added in addedFiles method
        if (result?.length > 0) {
            this.fileUploaded.emit(this.files);
        }
    }

    resetDropzoneIfSingle() {
        if (!this.config.multiple) {
            this.resetDropzone();
        }
    }

    resetDropzone() {
        this.files = []; // reset files to enable changing it again
        this.directiveRef?.reset();
    }

    removeFile(index: number, mouseEvent: MouseEvent) {
        mouseEvent.stopPropagation();
        mouseEvent.preventDefault();
        const [file] = this.files.splice(index, 1);
        this.directiveRef?.dropzone().removeFile(file);
        this.fileRemoved.emit(this.files);
    }
}
