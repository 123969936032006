<div
    class="
        breadcrumbs-container
        flex
        self-stretch
        justify-between
        my-12
        flex-wrap
        gap-12
    "
>
    <div class="header-container flex flex-col gap-8">
        <h1 class="font-header-4 text-header-4 text-on-light-base">
            {{ currentPage.title }}
            <span class="font-header-5 text-on-light-secondary">{{
                currentPage.subTitle
            }}</span>
        </h1>
        <div class="breadcrumbs flex items-center gap-2">
            <ng-container *ngFor="let page of pages; let isLast = last">
                <a
                    class="font-label-3 text-label-3 text-on-light-secondary"
                    [class.cursor-default]="isLast || page.isDisableNavigation"
                    [routerLink]="
                        isLast || page.isDisableNavigation ? [] : [page.url]
                    "
                    >{{ page.breadcrumbItemText }}</a
                >
                <ic-icon
                    *ngIf="!isLast"
                    class="text-on-light-secondary text-label-3"
                    [icon]="dividerIcon"
                ></ic-icon>
            </ng-container>
        </div>
    </div>
    <div
        class="left-container flex items-center"
        *ngIf="currentPage.breadcrumbsRightTemplate"
    >
        <ng-container
            *ngTemplateOutlet="currentPage.breadcrumbsRightTemplate"
        ></ng-container>
    </div>
</div>

<ng-content></ng-content>
