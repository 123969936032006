<shared-ui-page pageTitle="Предложения по функционалу">
    <shared-ui-button
        *ngIf="items.length > 0"
        breadcrumbsRightTemplate
        (click)="add()"
    >
        Предложить функционал
    </shared-ui-button>
    <ng-container *ngIf="items.length > 0; else empty">
        <div class="feature-info-container">
            <ng-container *ngFor="let item of items">
                <company-ui-feature-info
                    [item]="item"
                    (dislikeClick)="dislike($event)"
                    (likeClick)="like($event)"
                ></company-ui-feature-info>
            </ng-container>
        </div>
    </ng-container>
</shared-ui-page>

<ng-template #empty>
    <div class="mt-20">
        <company-ui-empty-feature-info
            (addFeature)="add()"
        ></company-ui-empty-feature-info>
    </div>
</ng-template>
