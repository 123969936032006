import { BaseComponent } from '@idocs/shared-ui/core';
import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { CompanyInfo, PersonInfo } from './account-card.models';
import domain from '@iconify/icons-mdi/domain';
import { IconifyIcon } from '@idocs/icons';
import { AppColor, AppColorVariants } from '@idocs/tailwind';
import { ImageConfig } from '@idocs/shared-ui/image';
/**
 * Базовый класс для карточки компании и физ лица
 */
@Directive()
export class BaseAccountCardComponent extends BaseComponent {
    protected personInfoValue?: PersonInfo;

    @Input()
    tooltip?: string | null;

    /**
     * Information about person
     */
    @Input()
    set personInfo(v: PersonInfo | undefined) {
        if (!v) {
            return;
        }

        if (this.personInfoValue === v) {
            return;
        }

        this.personInfoValue = v;

        if (v.position && v.position?.length > 138) {
            this.positionTooltip = v.position;
        }

        this.personImageConfig = new ImageConfig({
            src: v?.imageInBase64,
            type: 'blob',
        });
    }
    get personInfo() {
        return this.personInfoValue;
    }

    @Input()
    cardColor?: `bg-${AppColor}-${AppColorVariants}`;

    protected companyInfoValue?: CompanyInfo;
    /**
     * Information about company
     */
    @Input()
    set companyInfo(v: CompanyInfo | undefined) {
        this.companyInfoValue = v;
        this.companyImageConfig = new ImageConfig({
            src: v?.imageInBase64,
            type: 'blob',
        });
    }
    get companyInfo() {
        return this.companyInfoValue;
    }

    companyImageConfig?: ImageConfig;
    personImageConfig?: ImageConfig;

    /**
     * Flag that identifies if the card has additional content section below
     */
    @Input()
    hasContent?: boolean | null = false;

    /**
     * Flag that identifies if the card shadow on the border side
     */
    @Input()
    isShaded?: boolean | null = true;

    /**
     * Event emitted on card click. If card has subscribers to event [hasSubscribers]{@link hasSubscribers} will be `true`
     */
    @Output()
    selected = new EventEmitter();

    /**
     * Default icon which shown if company not set avatar
     */
    companyIcon: IconifyIcon = domain;

    /**
     * Flag to identify fail to load image for person info
     */
    imageFailedToLoad = false;

    /**
     * Flag for showing mat tooltip for company name
     */
    @Input()
    showCompanyNameTooltip = false;

    positionTooltip = '';

    /**
     * Flag that identifies if card has subscribers to `selected`{ @link BaseAccountCardComponent#selected }
     */
    get hasSubscribers() {
        return this.selected.observers.length > 0;
    }
}
