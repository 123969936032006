<div class="image-wrapper flex items-center justify-center flex-auto">
    <ng-container *ngIf="!showAPlug && (imgSource || icon); else plug">
        <img
            [class.rounded-full]="rounded"
            *ngIf="imgSource"
            class="w-full h-full object-cover"
            [src]="imgSource"
         alt=""/>
        <ic-icon *ngIf="icon" [icon]="icon"></ic-icon>
    </ng-container>
    <ng-template #plug>
        <div class="text-[24px]">
            <ic-icon [icon]="plugSrc"></ic-icon>
        </div>
    </ng-template>
</div>
