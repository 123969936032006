import { BaseModel, StrictBaseModelPart, hasUnionKeys } from '@dev-stream/utils';

export class NewFeatureApplication_Add_FormData extends BaseModel<NewFeatureApplication_Add_FormData> {
    protected BASE_TYPE = NewFeatureApplication_Add_FormData;
    'Data.Phone'!: string | null;
    'Data.Email'!: string | null;
    'Data.Title'!: string | null;
    'Data.Description'!: string | null;
    'Data.IsInternal'!: boolean | null;
    'Data.IsExternal'!: boolean | null;
    'Files'!: Array<Blob | null>;

    public GetDefaultValue(): StrictBaseModelPart<NewFeatureApplication_Add_FormData> {
        return {
            'Data.Phone': null,
            'Data.Email': null,
            'Data.Title': null,
            'Data.Description': null,
            'Data.IsInternal': null,
            'Data.IsExternal': null,
            Files: [],
        };
    }

    public static isNewFeatureApplication_Add_FormData(o: any): o is NewFeatureApplication_Add_FormData {
        return (
            o instanceof NewFeatureApplication_Add_FormData ||
            hasUnionKeys(o, [
                'Data.Phone',
                'Data.Email',
                'Data.Title',
                'Data.Description',
                'Data.IsInternal',
                'Data.IsExternal',
                'Files',
            ])
        );
    }
}
