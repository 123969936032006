import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteBuilder } from 'ng-utils';
import { DocumentsPolicies } from './auth/auth.policies';
import { DocumentsAppLayoutComponent } from './documents-app-components/documents-app-layout/documents-app-layout.component';

const createRootRoute = (children: Routes): Routes => [
    RouteBuilder.withPath('')

        .protectWith(
            DocumentsPolicies.Authenticated,
            async (_, authService) => {
                authService.authenticate();
            }
        )
        .withLayout(DocumentsAppLayoutComponent)
        .addChildren(...children)
        .build(),
];

const routes: Routes = [
    RouteBuilder.withPath('').asRedirectRoute('docs').build(),
    RouteBuilder.withPath('docs')
        .withLoadChildren(() =>
            import(
                './pages/zup-document-preview-page/zup-document-preview-page.module'
            ).then((m) => m.ZupDocumentPreviewPageModule)
        )
        .build(),
];

@NgModule({
    imports: [
        RouterModule.forRoot(createRootRoute(routes), {
    paramsInheritanceStrategy: 'always',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'ignore',
    urlUpdateStrategy: 'eager',
    initialNavigation: 'enabledBlocking'
}),
    ],
    exports: [RouterModule],
})
export class DocumentsAppRoutingModule {}
