import { createPolicy } from 'ng-authorize';
import { AuthService } from './auth.service';
import { AccountPermissions } from '@idocs/api/business/models/AccountPermissions.model';

export namespace DocumentsPolicies {
    export const Authenticated = createPolicy(
        'documents.policies.Authenticated',
        async (authService: AuthService) => authService.isAuthenticated(),
        [AuthService]
    );
}

export namespace ActionsPolicies {
    export namespace InternalPolicies {
        export const InternalAllDocumentEdit = createPolicy(
            'documents.policies.InternalAllDocumentEdit',
            async (authService: AuthService) =>
                authService.hasAccountPermissions(
                    AccountPermissions.INTERNAL_ALL_DOCUMENTS_EDIT
                ),
            [AuthService]
        );
    }
}

export const DOCUMENTS_POLICIES = [
    DocumentsPolicies.Authenticated,
    ActionsPolicies.InternalPolicies.InternalAllDocumentEdit,
];
