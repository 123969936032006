import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';

@Component({
    selector: 'shared-ui-contacts-card',
    templateUrl: './contacts-card.component.html',
    styleUrls: ['./contacts-card.component.scss'],
})
export class ContactsCardComponent extends BaseComponent implements OnInit {
    @Input()
    emails: string[] = [];

    @Input()
    phones: string[] = [];

    @Input()
    cardTitle?: string;

    @Input()
    description?: string;

    @Input()
    appearance: 'raised' | 'default' = 'default';

    @Output()
    feedback = new EventEmitter();

    ngOnInit(): void {}
}
