import {
    Component,
    forwardRef,
    Injector,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { FeedbackFormModel } from '../form-model';
import { BaseComponent } from '@idocs/shared-ui/core';
import { IFormControl } from 'ng-utils/extensions/form-control.extensions';
import { emailRegEx } from 'ng-utils/utils/emailRegEx';
import { merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    FileUploaderComponent,
    FileUploaderConfig,
} from '@idocs/shared-ui/file-uploader';

@Component({
    selector: 'shared-ui-feedback-form',
    templateUrl: './feedback-form.component.html',
    styleUrls: ['./feedback-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FeedbackFormComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => FeedbackFormComponent),
            multi: true,
        },
    ],
})
export class FeedbackFormComponent
    extends BaseComponent
    implements OnInit, ControlValueAccessor, Validator
{
    nameControl: IFormControl<string | null> = new FormControl('', [
        Validators.required,
    ]);
    phoneControl: IFormControl<string | null> = new FormControl('', [
        Validators.required,
    ]);
    emailControl: IFormControl<string | null> = new FormControl('', [
        Validators.required,
        Validators.pattern(emailRegEx),
    ]);
    companyNameControl: IFormControl<string | null> = new FormControl('', [
        Validators.required,
    ]);
    descriptionControl: IFormControl<string | null> = new FormControl('', [
        Validators.required,
    ]);

    constructor(injector: Injector) {
        super(injector);
        merge(
            ...this.controls.map((c) =>
                c.valueChanges.pipe(takeUntil(this.destroyed))
            )
        )
            .pipe(takeUntil(this.destroyed))
            .subscribe(() => this.onChange(this.getValue()));
    }

    getValue() {
        return new FeedbackFormModel({
            description: this.descriptionControl.value ?? '',
            email: this.emailControl.value ?? '',
            phone: this.phoneControl.value ?? '',
            companyName: this.companyNameControl.value ?? '',
            name: this.nameControl.value ?? '',
            files: this.files,
        });
    }

    onChange: (value: FeedbackFormModel) => void = () => {};
    onTouched: () => void = () => {};

    files: File[] = [];

    private controls = [
        this.nameControl,
        this.phoneControl,
        this.emailControl,
        this.companyNameControl,
        this.descriptionControl,
    ];

    @ViewChild('fileUploader', { static: true })
    fileUploader?: FileUploaderComponent;

    fileUploaderConfig: FileUploaderConfig = new FileUploaderConfig({
        maxFilesAllowed: 5,
        maxFileSize: 2,
    });

    ngOnInit(): void {}

    writeValue(value: FeedbackFormModel) {
        this.nameControl.reset(value.name, { emitEvent: false });
        this.phoneControl.reset(value.phone, { emitEvent: false });
        this.emailControl.reset(value.email, { emitEvent: false });
        this.companyNameControl.reset(value.companyName, { emitEvent: false });
        this.descriptionControl.reset(value.description, { emitEvent: false });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.controls.forEach((control) => {
            if (isDisabled) {
                control.disable({ emitEvent: false });
            } else {
                control.enable({ emitEvent: false });
            }
        });
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return this.controls.some((c) => c.invalid)
            ? {
                  name: this.nameControl.errors,
                  phone: this.phoneControl.errors,
                  email: this.emailControl.errors,
                  companyName: this.companyNameControl.errors,
                  description: this.descriptionControl.errors,
              }
            : null;
    }

    filesRemoved(_: File[]) {
        this.files = this.fileUploader?.files ?? [];
        console.log(this.files);
        this.onChange(this.getValue());
    }

    filesAdded(_: File[]) {
        this.files = this.fileUploader?.files ?? [];
        console.log(this.files);
        this.onChange(this.getValue());
    }
}
