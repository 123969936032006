import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AccountCardModule } from '@idocs/shared-ui/account-card';
import { AppIconsModule } from '@idocs/shared-ui/app-icons';
import { BreadcrumbsModule } from '@idocs/shared-ui/breadcrumbs';
import { CrossPortalNavigationMenuModule } from '@idocs/shared-ui/cross-portal-navigation-menu';
import { HelpPagesComponentsModule } from '@idocs/shared/help-pages';
import { MatMenuModule } from '@angular/material/menu';
import { NgAuthorizeModule } from 'ng-authorize';
import { DocumentsAppLayoutComponent } from './documents-app-layout/documents-app-layout.component';
import { DrawerLayoutModule } from '@idocs/shared-ui/drawer-layout';

@NgModule({
    declarations: [DocumentsAppLayoutComponent],
    imports: [
        CommonModule,
        RouterModule,
        AccountCardModule,
        DrawerLayoutModule,
        AppIconsModule,
        BreadcrumbsModule,
        CrossPortalNavigationMenuModule,
        HelpPagesComponentsModule,
        MatMenuModule,
        NgAuthorizeModule,
    ],
    exports: [DocumentsAppLayoutComponent],
})
export class DocumentsAppComponentsModule {}
