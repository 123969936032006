import { Component, Injector, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';

type StatusInfoColors =
    | 'primary'
    | 'secondary'
    | 'accent'
    | 'info'
    | 'success'
    | 'warning'
    | 'danger'
    | 'transparentSecondary'
    | 'secondaryLight'
    | 'secondaryDark';

@Component({
    selector: 'shared-ui-status-info',
    templateUrl: './status-info.component.html',
    styleUrls: ['./status-info.component.scss'],
})
export class StatusInfoComponent extends BaseComponent implements OnInit {
    @Input() text?: string;
    @Input() color: StatusInfoColors = 'primary';

    styles: { [key in StatusInfoColors]: string[] } = {
        primary: [
            'bg-primary-extra-lighter',
            'text-primary-main',
            'border-primary-main',
        ],
        secondary: [
            'bg-secondary-extra-lighter',
            'text-secondary-main',
            'border-secondary-main',
        ],
        accent: [
            'bg-accent-extra-lighter',
            'text-accent-main',
            'border-accent-main',
        ],
        info: ['bg-info-extra-lighter', 'text-info-main', 'border-info-main'],
        success: [
            'bg-success-extra-lighter',
            'text-success-main',
            'border-success-main',
        ],
        warning: [
            'bg-warning-extra-lighter',
            'text-warning-main',
            'border-warning-main',
        ],
        danger: [
            'bg-danger-extra-lighter',
            'text-danger-main',
            'border-danger-main',
        ],
        transparentSecondary: [
            'text-on-light-secondary',
            'border-secondary-extra-lighter',
        ],

        secondaryDark: [
            'text-on-dark-secondary',
            'bg-secondary-main',
            'border-secondary-main',
        ],
        secondaryLight: ['text-on-light-secondary', 'border-secondary-main'],
    };

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {}

    get statusStyles() {
        return [...this.styles[this.color]];
    }
}
