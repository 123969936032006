import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteBuilder } from 'ng-utils';
import { ContactsPageComponent } from './pages/contacts-page/contacts-page.component';
import { FeaturesPageComponent } from './pages/features-page/features-page.component';
import { DownloadsPageComponent } from './pages/downloads-page/downloads-page.component';
import { InstructionsPageComponent } from './pages/instructions-page/instructions-page.component';

const routes: Routes = [
    RouteBuilder.withPath('').asRedirectRoute('contacts', 'full').build(),
    RouteBuilder.withPath('contacts')
        .withComponent(ContactsPageComponent)
        .build(),
    RouteBuilder.withPath('features')
        .withComponent(FeaturesPageComponent)
        .build(),
    RouteBuilder.withPath('downloads')
        .withComponent(DownloadsPageComponent)
        .build(),
    RouteBuilder.withPath('instructions')
        .withComponent(InstructionsPageComponent)
        .build(),
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class HelpPagesRoutingModule {}
