import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mapLeft, mapRight } from '@dev-stream/utils';
import { ApplicationNewFeatureViewModel } from '@idocs/api/corporate/models/ApplicationNewFeatureViewModel.model';
import { ClientApplicationNewFeatureStatus } from '@idocs/api/corporate/models/ClientApplicationNewFeatureStatus.model';
import { CurrentUserLikeOrDislike } from '@idocs/api/corporate/models/CurrentUserLikeOrDislike.model';
import { NewFeatureApplication_Add_FormData } from '@idocs/api/corporate/models/NewFeatureApplication_Add_FormData.model';
import { NewFeatureApplicationApiService } from '@idocs/api/corporate/services/NewFeatureApplication.api-service';
import { FeatureInfoVm } from '@idocs/company-ui/components/feature-info';
import { FeatureInfoFormVm } from '@idocs/company-ui/components/feature-info-form';
import { FeatureInfoFormComponent } from '@idocs/company-ui/components/feature-info-form/feature-info-form/feature-info-form.component';
import { apiResponseErrorHandler, Disposable } from '@idocs/shared-logic';
import { BladeAction, BladeConfig, BladeService } from '@idocs/shared-ui/blade';
import {
    NotificationConfiguration,
    showNotification,
} from '@idocs/shared-ui/notification';
import { map, takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SharedFeatureService extends Disposable {
    constructor(
        private blade: BladeService,
        private featureApi: NewFeatureApplicationApiService
    ) {
        super();
    }

    statusMap = new Map<ClientApplicationNewFeatureStatus | null, string>([
        [ClientApplicationNewFeatureStatus.Finished, 'Реализован'],
        [ClientApplicationNewFeatureStatus.Declined, 'Отклонено'],
        [ClientApplicationNewFeatureStatus.InProgress, 'В разработке'],
        [ClientApplicationNewFeatureStatus.OnReviewing, 'На рассмотрении'],
        [null, 'Не определен'],
    ]);

    getFeatures() {
        return this.featureApi
            .Get()
            .pipe(
                takeUntil(this.destroyed),
                mapLeft(apiResponseErrorHandler),
                mapLeft(showNotification)
            );
    }

    mapToFeatureInfo(items: ApplicationNewFeatureViewModel[]) {
        return items.map(
            (r) =>
                new FeatureInfoVm({
                    ApplicationId: r.ApplicationId ?? '',
                    Title: r.Title ?? '',
                    Content: r.Description ?? '',
                    LikeCount: r.Likes ?? 0,
                    DislikeCount: r.DisLikes ?? 0,
                    Status: this.statusMap.get(r.Status),
                    Disliked:
                        r.CurrentUserVote === CurrentUserLikeOrDislike.Disliked,
                    Liked: r.CurrentUserVote === CurrentUserLikeOrDislike.Liked,
                })
        );
    }

    openAddFeatueBlade() {
        return this.blade.open(
            new BladeConfig<
                FeatureInfoFormComponent,
                null,
                FeatureInfoFormVm | null
            >({
                side: 'right',
                title: 'Предложить функционал',
                id: 'company-feature-add',
                actions: [
                    new BladeAction({
                        text: 'Отменить',
                        color: 'accent',
                        appearance: 'outline',
                        result: null,
                    }),
                    new BladeAction({
                        text: 'ОТПРАВИТЬ',
                        color: 'accent',
                        result: (component) => component.getResult(),
                        disabled: (component) => !component.isFormValid(),
                    }),
                ],
                componentOrTemplate: FeatureInfoFormComponent,
                beforeClose: this.addFeatureBladeBeforeClose.bind(this),
            })
        );
    }

    private async addFeatureBladeBeforeClose(
        result: FeatureInfoFormVm | null | undefined
    ): Promise<boolean> {
        if (!result) {
            return true;
        }

        return await this.featureApi
            .Add_byFormData(
                new NewFeatureApplication_Add_FormData({
                    'Data.Description': result.Content,
                    'Data.Title': result.Title,
                    Files: result.Files,
                    'Data.IsExternal': result.IsExternal,
                    'Data.IsInternal': result.IsInternal,
                })
            )
            .pipe(
                takeUntil(this.destroyed),
                mapRight((res) => {
                    return new NotificationConfiguration({
                        color: 'success',
                        title: 'Успешно',
                        content:
                            'Предложение по функционалу успешно отправлено!',
                    });
                }),
                mapRight(showNotification),
                mapLeft(apiResponseErrorHandler),
                mapLeft(showNotification),
                map((r) => r.isRight())
            )
            .toPromise();
    }

    like(id: string) {
        return this.featureApi
            .Like_byApplicationId({ applicationId: id })
            .pipe(
                takeUntil(this.destroyed),
                mapLeft(apiResponseErrorHandler),
                mapLeft(showNotification)
            );
    }

    dislike(id: string) {
        return this.featureApi
            .DisLike_byApplicationId({ applicationId: id })
            .pipe(
                takeUntil(this.destroyed),
                mapLeft(apiResponseErrorHandler),
                mapLeft(showNotification)
            );
    }
}
